import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import Public from './../../../Layout/Public'
import SearchBoxBG from './../../../components/SearchBox/SearchBoxBG'
import Manufacturers from './Manufacturers'
import NewArrivals from './NewArrivals'
import { storeSearchEnums, clearSearchEnums, newArrivals } from './../../../features/homePage/homePageSlice'
import { useGetSearchEnumsMutation, useGetNewArrivalCarsMutation } from './../../../features/homePage/homePageApi'
import { setCurrentPage } from './../../../features/other/otherSlice'

const HomePage = () => {
  const dispatch = useDispatch()
  // const { manufacturersId, models } = useSelector(state => state.cars)
  const [getNewArrivalCars, { data, isLoading: newArrivalCarsIsLoading, isError: newArrivalCarsIsError }] = useGetNewArrivalCarsMutation() || {}
  console.log('NewArrivalCars', data);
  
  const [
    getSearchEnums,
    { data: allSearchEnums, isLoading, isError }
  ] = useGetSearchEnumsMutation() || {}

  useEffect(() => {
    const formData = new FormData()
    formData.append('Page', 0)
    dispatch(clearSearchEnums())
    getNewArrivalCars({ formData })
    getSearchEnums()
    dispatch(setCurrentPage({
      currentPage: 'home'
    }))
  }, [])

  useEffect(() => {
    if (!isLoading && !isError && allSearchEnums?.data) {
      dispatch(
        storeSearchEnums({
          searchEnums: allSearchEnums.data
        })
      )
    }
  }, [allSearchEnums])

  return (
    <Public>
      <SearchBoxBG buttonColor='bg-neutral-200'/>
      <Manufacturers />
      {newArrivalCarsIsLoading ?
        <div className='flex justify-center items-center my-20 w-full'>
          <PulseLoader color="var(--secondary-color)" size={25} />
        </div>
        :
        (!newArrivalCarsIsLoading && !data?.isSuccess) ?
        null
        :
        <div className='bg-white'>
          <NewArrivals data={data.data} />
        </div>

      }
    </Public>
  )
}

export default HomePage
