import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useGetModelCountByManufacIDMutation } from './../../../features/homePage/homePageApi'
import { setFilterBoxItems, reloadPage } from './../../../features/carsPage/carsPageSlice'
import Multiselect from 'multiselect-react-dropdown'
import MultiRangeSlider from 'multi-range-slider-react'
import Button from '../../../components/Buttons/Button'



const LeftFilterBox = ({ allOverViewInfoPub, allOverViewInfoPubLoading, specInfoPubData, specInfoPubIsLoading, specInfoPubIsError }) => {
  const date = new Date()
  const latestYear = date.getFullYear()
	const dispatch = useDispatch()
  const { searchEnums } = useSelector(state => state.homePage) || {}

  const [manufacId, setManufacId] = useState(undefined)
  const [model, setModel] = useState(undefined)
  const [minYearValue, setMinYearValue] = useState(1971)
  const [maxYearValue, setMaxYearValue] = useState(latestYear)
  const [minPriceValue, setMinPriceValue] = useState(0)
  const [maxPriceValue, setMaxPriceValue] = useState(1000000)
  const [minMileageValue, setMinMileageValue] = useState(0)
  const [maxMileageValue, setMaxMileageValue] = useState(1000)
  const [minDisplacementValue, setMinDisplacementValue] = useState(0)
  const [maxDisplacementValue, setMaxDisplacementValue] = useState(4000)
  const [minSeatingCapacityValue, setMinSeatingCapacityValue] = useState(2)
  const [maxSeatingCapacityValue, setMaxSeatingCapacityValue] = useState(16)
  const [transmissionSelection, setTransmissionSelection] = useState('')
  const [driveSystemSelection, setDriveSystemSelection] = useState('')
  const [repairedSelection, setRepairedSelection] = useState('')
  const [fuelSelection, setFuelSelection] = useState('')
  const [color, setColor] = useState('')
  const [steeringSelection, setSteeringSelection] = useState('')
  const [carConditionsSelection, setCarConditionsSelection] = useState('')
  const [equipmentsSelection, setEquipmentsSelection] = useState('')
  const [intExtsSelection, setIntExtsSelection] = useState('')
  const [safetyEquipsSelection, setSafetyEquipsSelection] = useState('')
  const [selfDrivingsSelection, setSelfDrivingsSelection] = useState('')
  const [standardFeaturesSelection, setStandardFeaturesSelection] = useState('')
  const searchD =
    'M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'

  const [
    getModelCountByManufacID,
    { data: modelCount, isLoading: modelCountLoading, isError: modelCountError }
  ] = useGetModelCountByManufacIDMutation() || {}
  

  const onManufacIdSelection = selectedList => {
    const item = searchEnums.manufacCount.find(m => m.manufacCount === selectedList[0])
    setManufacId(parseInt(item.id))
    const formData = new FormData()
    formData.append('Id', parseInt(item.id))
    getModelCountByManufacID({ formData })
  }
  const onModelSelection = selectedList => {
    const item = modelCount.data.find(i => i.modelCount === selectedList[0])
    setModel(item.modelName)
  }
  const handleYearInput = e => {
    setMinYearValue(e.minValue)
    setMaxYearValue(e.maxValue)
  }
  const handlePriceInput = e => {
    setMinPriceValue(e.minValue)
    setMaxPriceValue(e.maxValue)
  }
  const handleMileageInput = e => {
    setMinMileageValue(e.minValue)
    setMaxMileageValue(e.maxValue)
  }
  const handleDisplacementInput = e => {
    setMinDisplacementValue(e.minValue)
    setMaxDisplacementValue(e.maxValue)
  }
  const handleSeatingCapacityInput = e => {
    setMinSeatingCapacityValue(e.minValue)
    setMaxSeatingCapacityValue(e.maxValue)
  }

  // allOverViewInfoPub Selections
  const onTransmissionSelection = selectedList => {
    const item = allOverViewInfoPub.transmissions.find(i=>i.transmission === selectedList[0])
    const id = item.id
    setTransmissionSelection(parseInt(id))
  }
  const onDriveSystemSelection = selectedList => {
    const item = allOverViewInfoPub.driveSystems.find(i=>i.driveSystem === selectedList[0])
    const id = item.id
    setDriveSystemSelection(parseInt(id))
  }
  const onFuelSelection = selectedList => {
    const item = allOverViewInfoPub.fuels.find(i=>i.fuel === selectedList[0])
    const id = item.id
    setFuelSelection(parseInt(id))
  }
  const onRepairedSelection = selectedItem => {
    const item = selectedItem[0] === 'Yes' ? true : false
    setRepairedSelection(item)
  }
  const onColorSelection = selectedItem => {
    setColor(selectedItem[0])
  }
  const onSteeringSelection = selectedList => {
    const item = allOverViewInfoPub.steertings.find(i=>i.steering === selectedList[0])
    const id = item.id
    setSteeringSelection(parseInt(id))
  }

  // specInfoHandler//
  const onConditionsSelection = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.carConditions.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setCarConditionsSelection(ids)
  }
  const onConditionsRemoval = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.carConditions.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setCarConditionsSelection(ids)
  }
  const onEquipmentsSelection = selectedList => {
    // console.log(selectedList)
    const items = selectedList.map(item => {
      const selected = specInfoPubData.equipments.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setEquipmentsSelection(ids)
  }
  const onEquipmentsRemoval = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.equipments.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setEquipmentsSelection(ids)
  }
  const onIntExtsSelection = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.intExts.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setIntExtsSelection(ids)
  }
  const onIntExtsRemoval = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.intExts.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setIntExtsSelection(ids)
  }
  const onSafetyEquipsSelection = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.safetyEquips.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setSafetyEquipsSelection(ids)
  }
  const onSafetyEquipsRemoval = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.safetyEquips.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setSafetyEquipsSelection(ids)
  }
  const onSelfDrivingsSelection = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.selfDrivings.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setSelfDrivingsSelection(ids)
  }
  const onSelfDrivingsRemoval = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.selfDrivings.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setSelfDrivingsSelection(ids)
  }
  const onStandardFeaturesSelection = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.standardFeatures.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setStandardFeaturesSelection(ids)
  }
  const onStandardFeaturesRemoval = selectedList => {
    const items = selectedList.map(item => {
      const selected = specInfoPubData.standardFeatures.find(
        m => m.names === item
      )
      return selected.id
    })
    const ids = items.toString()
    setStandardFeaturesSelection(ids)
  }
  
  // console.log(color)


  const onFilterHandeler = () => {
		dispatch(setFilterBoxItems({
			manufacturer: manufacId,
			model: model,
			minYear: minYearValue,
			maxYear: maxYearValue,
			minPrice: minPriceValue,
			maxPrice: maxPriceValue,
      mileageStart: minMileageValue,
      mileageEnd: maxMileageValue,
      displacementStart: minDisplacementValue,
      displacementEnd: maxDisplacementValue,
      transmissionId: transmissionSelection,
      fuelId: fuelSelection,
      driveSystemId: driveSystemSelection,
      steeringId: steeringSelection,
      isRepaired: repairedSelection,
      colors: color,
      seatingCapacityStart: minSeatingCapacityValue,
      seatingCapacityEnd: maxSeatingCapacityValue,
      conditionIds: carConditionsSelection,
      equipmentIds: equipmentsSelection,
      intExtIds: intExtsSelection,
      safetyEquipsIds: safetyEquipsSelection,
      selfDrivingsIds: selfDrivingsSelection,
      standardFeatureIds: standardFeaturesSelection
		}))
    dispatch(reloadPage())
	}






  return (
    <div className='w-full sm:w-4/12 h-fit bg-white p-4 shadow-xl drop-shadow-lg rounded-md'>
      <div className='flex flex-col justify-between'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Manufacturer
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={searchEnums?.manufacCount.map(i => i.manufacCount)}
          singleSelect={true}
          // loading={specInfoPubIsLoading}
          onSelect={onManufacIdSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Models
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={modelCount?.data?.map(i => i.modelCount)}
          singleSelect={true}
          loading={modelCountLoading}
          onSelect={onModelSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='w-full mr-3'>
        <label className={`${'text-cadmium'} text-sm font-bold`}>
          Year
        </label>
        <label className='flex justify-between'>
          <span className={`${'text-black2 font-bold'}`}>1971</span>
          <span className={`${'text-black2 font-bold'}`}>{latestYear}</span>
        </label>
        <MultiRangeSlider
          style={{
            border: 'none',
            boxShadow: 'none'
          }}
          thumbLeftColor={'#FF0000'}
          barInnerColor={'#FFFFFF'}
          thumbRightColor={'#FF0000'}
          label={false}
          ruler={false}
          min={1971}
          max={latestYear}
          step={1}
          minValue={1971}
          maxValue={latestYear}
          onInput={e => {
            handleYearInput(e)
          }}
        />
      </div>
      <div className='w-full mr-3'>
        <label className={`${'text-cadmium'} text-sm font-bold`}>
          Price
        </label>
        <label className='flex justify-between'>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('minPrice')
              ? 0
              : searchEnums.minPrice}
          </span>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('maxPrice')
              ? 1000000
              : searchEnums.maxPrice}
          </span>
        </label>
        <MultiRangeSlider
          style={{
            border: 'none',
            boxShadow: 'none'
          }}
          thumbLeftColor={'#FF0000'}
          barInnerColor={'#FFFFFF'}
          thumbRightColor={'#FF0000'}
          label={false}
          ruler={false}
          min={searchEnums?.minPrice}
          max={searchEnums?.maxPrice}
          step={1}
          minValue={searchEnums?.minPrice}
          maxValue={searchEnums?.maxPrice}
          onInput={e => {
            handlePriceInput(e)
          }}
        />
      </div>
      <div className='w-full mr-3'>
        <label className={`${'text-cadmium'} text-sm font-bold`}>
          Mileage
        </label>
        <label className='flex justify-between'>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('minMileage')
              ? 0
              : searchEnums.minMileage}
          </span>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('maxMileage')
              ? 1000000
              : searchEnums.maxMileage}
          </span>
        </label>
        <MultiRangeSlider
          style={{
            border: 'none',
            boxShadow: 'none'
          }}
          thumbLeftColor={'#FF0000'}
          barInnerColor={'#FFFFFF'}
          thumbRightColor={'#FF0000'}
          label={false}
          ruler={false}
          min={searchEnums?.minMileage}
          max={searchEnums?.maxMileage}
          step={1}
          minValue={searchEnums?.minMileage}
          maxValue={searchEnums?.maxMileage}
          onInput={e => {
            handleMileageInput(e)
          }}
        />
      </div>
      <div className='w-full mr-3'>
        <label className={`${'text-cadmium'} text-sm font-bold`}>
          Displacement
        </label>
        <label className='flex justify-between'>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('minDisplacement')
              ? 0
              : searchEnums.minDisplacement}
          </span>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('maxDisplacement')
              ? 1000000
              : searchEnums.maxDisplacement}
          </span>
        </label>
        <MultiRangeSlider
          style={{
            border: 'none',
            boxShadow: 'none'
          }}
          thumbLeftColor={'#FF0000'}
          barInnerColor={'#FFFFFF'}
          thumbRightColor={'#FF0000'}
          label={false}
          ruler={false}
          min={searchEnums?.minDisplacement}
          max={searchEnums?.maxDisplacement}
          step={1}
          minValue={searchEnums?.minDisplacement}
          maxValue={searchEnums?.maxDisplacement}
          onInput={e => {
            handleDisplacementInput(e)
          }}
        />
      </div>
      <div className='flex flex-col justify-between'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Transmission
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={allOverViewInfoPub?.transmissions.map(i=> i.transmission)}
          singleSelect={true}
          loading={allOverViewInfoPubLoading}
          onSelect={onTransmissionSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Drive
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={allOverViewInfoPub?.driveSystems.map(i=> i.driveSystem)}
          singleSelect={true}
          loading={allOverViewInfoPubLoading}
          onSelect={onDriveSystemSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Fuel
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={allOverViewInfoPub?.fuels.map(i=> i.fuel)}
          singleSelect={true}
          loading={allOverViewInfoPubLoading}
          onSelect={onFuelSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Repaired
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={['Yes', 'No']}
          singleSelect={true}
          loading={allOverViewInfoPubLoading}
          onSelect={onRepairedSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Color
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={searchEnums?.colors}
          singleSelect={true}
          // loading={allOverViewInfoPubLoading}
          onSelect={onColorSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Steering
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          options={allOverViewInfoPub?.steertings.map(i=> i.steering)}
          singleSelect={true}
          loading={allOverViewInfoPubLoading}
          onSelect={onSteeringSelection}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='w-full mr-3'>
        <label className={`${'text-cadmium'} text-sm font-bold`}>
          Seating Capacity
        </label>
        <label className='flex justify-between'>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('minSeatingCapacity')
              ? 0
              : searchEnums.minSeatingCapacity}
          </span>
          <span className={`${'text-black2 font-bold'}`}>
            {!searchEnums?.hasOwnProperty('maxSeatingCapacity')
              ? 1000000
              : searchEnums.maxSeatingCapacity}
          </span>
        </label>
        <MultiRangeSlider
          style={{
            border: 'none',
            boxShadow: 'none'
          }}
          thumbLeftColor={'#FF0000'}
          barInnerColor={'#FFFFFF'}
          thumbRightColor={'#FF0000'}
          label={false}
          ruler={false}
          min={searchEnums.minSeatingCapacity}
          max={searchEnums.maxSeatingCapacity}
          step={1}
          minValue={searchEnums.minSeatingCapacity}
          maxValue={searchEnums.maxSeatingCapacity}
          onInput={e => {
            handleSeatingCapacityInput(e)
          }}
        />
      </div>
      <div className='flex flex-col justify-between'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Car Conditions
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          // singleSelect={true}
          loading={specInfoPubIsLoading}
          options={specInfoPubData?.carConditions.map(
            item => item.names
          )}
          onSelect={onConditionsSelection}
          onRemove={onConditionsRemoval}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Equipments
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '50px'
            }
          }}
          isObject={false}
          // singleSelect={true}
          loading={specInfoPubIsLoading}
          options={specInfoPubData?.equipments.map(
            item => item.names
          )}
          onSelect={onEquipmentsSelection}
          onRemove={onEquipmentsRemoval}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Interior/Exterior
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              // dispalay: 'flex',
              // flexDirection: 'column',
              // justifyContents: 'center',
              // alignItems: 'center',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '50px'
            }
          }}
          isObject={false}
          // singleSelect={true}
          loading={specInfoPubIsLoading}
          options={specInfoPubData?.intExts.map(
            item => item.names
          )}
          onSelect={onIntExtsSelection}
          onRemove={onIntExtsRemoval}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Safety Equipement
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          // singleSelect={true}
          loading={specInfoPubIsLoading}
          options={specInfoPubData?.safetyEquips.map(
            item => item.names
          )}
          onSelect={onSafetyEquipsSelection}
          onRemove={onSafetyEquipsRemoval}
        // onSelect={}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
          Self-driving
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          // singleSelect={true}
          loading={specInfoPubIsLoading}
          options={specInfoPubData?.selfDrivings.map(
            item => item.names
          )}
          onSelect={onSelfDrivingsSelection}
          onRemove={onSelfDrivingsRemoval}
        // onSelect={}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex flex-col justify-between mt-3'>
        <label className='mr-4 mb-1 text-sm font-bold text-cadmium'>
        Standard Features
        </label>
        <Multiselect
          style={{
            inputField: {
              // margin: '24px',
              // width: '40px',
              padding: '0px',
              height: '16px'
            },
            // chips: { background: "red" },
            searchBox: {
              // To change search box element look
              fontSize: '15px',
              padding: '2px 5px',
              // width: '380px',
              // borderRadius: '50px',
              borderStyle: 'none',
              // height: '10px'
            },
            multiselectContainer: {
              color: 'black',
              backgroundColor: '#FFFFFF',
              border: '1px #E5E5E5 solid',
              borderRadius: '10px',
              //'#e5e5e5'
              // height: '10px'
            },
            // optionContainer: { // To change css for option container
            //   border: '2px solid'
            //   height: '30px'
            // },
            option: {
              // To change css for dropdown options
              //color: 'blue',
              fontSize: '15px',
              padding: '5px',
              backgroundColor: '#B2BEB5',
              height: '32px'
            }
          }}
          isObject={false}
          // singleSelect={true}
          loading={specInfoPubIsLoading}
          options={specInfoPubData?.standardFeatures.map(
            item => item.names
          )}
          onSelect={onStandardFeaturesSelection}
          onRemove={onStandardFeaturesRemoval}
        // onSelect={}
        // selectedValues={['One Owner']}
        />
      </div>
      <div className='flex justify-center items-center w-full mt-4'>
        <Button
          title='Search'
          textColor='text-white'
          bgColor='bg-secondary'
          active='active:bg-red-400'
          d={searchD}
          onClickHandeler={onFilterHandeler}
          link='/cars/carList/filter-cars'
          deactive={(manufacId && model) ? false : true}
        />
      </div>
    </div>
  )
}

export default LeftFilterBox
