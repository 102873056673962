import SingleQues from './SingleQues'

const GroupQuesBlock = ({title, data}) => {
	return (
		<div className='flex flex-col w-fit mb-4'>
			<div className='text-xl font-bold mb-1'>{title}</div>
			{data.map(item => <SingleQues key={item.id} item={item} />)}
		</div>
	)
}

export default GroupQuesBlock
