import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Public from '../../../Layout/Public'
import AtoZ from './AtoZ'
import SearchBar from './../../../components/SearchBar/SearchBar'
import { useGetAllCarModelsByManufacsMutation } from '../../../features/carsPage/carsPageApi'
import { allCarModelsByManufacs, removeModels } from '../../../features/carsPage/carsPageSlice'
import { setCurrentPage } from './../../../features/other/otherSlice'
import LoopingBlock from './LoopingBlock'
import PulseLoader from 'react-spinners/PulseLoader'

const CarModelsPage = () => {
  const dispatch = useDispatch()
  const [clicked, setClicked] = useState(false)
  const [carModelsByManufacs, setCarModelsByManufacs] = useState([])
  const [getAllCarModelsByManufacs, { data, isLoading, isError }] =
    useGetAllCarModelsByManufacsMutation() || {}
  const { manufacturersId, models } = useSelector(state => state.cars)

  useEffect(() => {
    const formData = new FormData()
    const ids = manufacturersId.join()
    formData.append('ManufacIds', ids)
    dispatch(removeModels())
    dispatch(setCurrentPage({
      currentPage: 'cars'
    }))
    getAllCarModelsByManufacs({ formData })
  }, [])

  useEffect(() => {
    if (!isLoading && !isError && data?.data) {
      setCarModelsByManufacs(data.data)
    }
  }, [dispatch, isLoading, isError, data])

  const clearFilterHandeler = () => {
    dispatch(removeModels())
    setClicked(!clicked)
  }

  return (
    <Public>
      <div className='mt-24'>
        <div className='hidden md:block'>
          <AtoZ />
        </div>
        <div className='mx-5 md:mx-10 lg:mx-20'>
          {models.length > 0 ? <SearchBar link='/cars/carList/model' clearHandeler={clearFilterHandeler} buttonActiveLength={models.length} /> : null}
        </div>
        <div className='w-auto h-auto mx-10'>
          <div id="decoration" className='indexClass'>
            <div id="pill-1" className="pill"></div>
            <div id="pill-2" className="pill"></div>
            <div id="pill-3" className="pill"></div>
            <div id="pill-4" className="pill"></div>
            <div id="pill-5" className="pill"></div>
            <div id="pill-6" className="pill"></div>
            <div id="pill-7" className="pill"></div>
            <div id="pill-8" className="pill"></div>
            <div id="pill-9" className="pill"></div>
            <div id="pill-10" className="pill"></div>
          </div>
        </div>
        {isLoading ? (
          <div className='flex justify-center items-center my-10 w-full'><PulseLoader color="var(--secondary-color)" size={25} /></div>
        ) : (
          <div className='mx-5 md:mx-10 lg:mx-20'>
            {carModelsByManufacs.map((models, index) => (
              <LoopingBlock key={index} data={models} clickedUnCheck={clicked} />
            ))}
          </div>
        )}

      </div>
    </Public>
  )
}

export default CarModelsPage
