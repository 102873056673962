import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	manufacturersList: [],
	newArrivalCars: [],
	searchEnums: [],
	modelCountByManufacID: [],
}

const homePageSlice = createSlice({
	name: 'homePage',
	initialState,
	reducers: {
		manufacturers: (state, action) => {
			state.manufacturersList = action.payload.manufacturersList
		},
		newArrivals: (state, action) => {
			state.newArrivalCars = action.payload.newArrivalCars
		},
		storeSearchEnums: (state, action) => {
			state.searchEnums = action.payload.searchEnums
		},
		clearSearchEnums: (state) => {
			state.searchEnums = []
		},
		storeModelCountByManufacID: (state, action) => {
			state.modelCountByManufacID = action.payload.modelCountByManufacID
		},
		removeModelCountByManufacID: (state,) => {
			state.modelCountByManufacID = []
		},
	}
})

export const { manufacturers, newArrivals, storeSearchEnums, clearSearchEnums, storeModelCountByManufacID, removeModelCountByManufacID } = homePageSlice.actions
export default homePageSlice.reducer