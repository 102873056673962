import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Button from '../../../components/Buttons/Button'
import Public from '../../../Layout/Public'
import { useSubmitInquiryMutation } from '../../../features/carsPage/carsPageApi'
import useWindowSize from '../../../hooks/useWindowSize'
import PulseLoader from 'react-spinners/PulseLoader'

const Inquiry = () => {
	const [size] = useWindowSize()
	const [name, setName] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [email, setEmail] = useState('')
	const [country, setCountry] = useState('')
	const [phone, setPhone] = useState('')
	const [Comment, setComment] = useState('')
	const [receiveNews, setReceiveNews] = useState(false)
	const [privacyPolicy, setPrivacyPolicy] = useState(false)
	const [show, setShow] = useState(false)
	const sendD = 'M10.5 13.5 21 3M10.627 13.828l2.628 6.758c.232.596.347.893.514.98a.5.5 0 0 0 .462 0c.167-.086.283-.384.515-.979L21.336 3.7c.21-.537.315-.805.258-.977a.5.5 0 0 0-.316-.316c-.172-.057-.44.048-.978.257L3.413 9.253c-.595.233-.893.349-.98.516a.5.5 0 0 0 0 .461c.087.167.385.283.98.514l6.758 2.629c.121.047.182.07.233.106a.5.5 0 0 1 .116.117c.037.051.06.111.107.232Z'
	const { singleCarId } = useSelector(state => state.cars)

	const [submitInquiry, { data, isLoading, isError }] =
		useSubmitInquiryMutation() || {}

	const cleanStates = () => {
		setName('')
		setCompanyName('')
		setEmail('')
		setCountry('')
		setPhone('')
		setComment('')
		setReceiveNews(false)
		setPrivacyPolicy(false)
	}

	const submitHandeler = () => {
		setShow(true)
		const formData = new FormData()
		formData.append('CarId', singleCarId.join())
		formData.append('Name', name)
		formData.append('CompanyName', companyName)
		formData.append('Email', email)
		formData.append('Country', country)
		formData.append('PhoneNumber', phone)
		formData.append('Comments', Comment)
		formData.append('ReceiveNewsletter', receiveNews)
		submitInquiry({ formData })
		// cleanStates()
	}

	const handeler = (phone, country, e, formattedValue) => {
		setPhone(formattedValue)
	}
	return (
		<Public>
			<div className='w-full h-full mt-20'>
				<div className='flex flex-col justify-center items-center w-full'>
					<div className='flex flex-row justify-center items-center mt-10'>
						<div className='flex justify-center items-center bg-green-600 w-20 h-20 rounded-full'><span className='text-xs text-white'>Information</span></div>
						<div className='bg-green-600 w-20 sm:w-48 h-1'></div>
						<div className={`flex justify-center items-center ${data?.isSuccess ? 'bg-secondary' : 'bg-green-600'} w-20 h-20 rounded-full`}><span className='text-xs text-white'>Complete</span></div>
					</div>
					{show ? <div className='my-10'>{!isLoading && !isError && data?.isSuccess ? <div className='text-4xl text-secondary font-bold'>{data.message}</div> : <div className='text-4xl text-secondary font-bold'>Your info is processing</div>}</div> : null}
					{!show ? <div className='text-4xl text-secondary font-bold text-center mt-6 w-full'>Enter Your Information</div> : null }
					{!show ? <div className='flex flex-col my-6 sm:my-16'>
						<div className='flex flex-col sm:flex-row justify-between w-full'>
							<label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
								<span className='text-secondary font-extrabold'>*</span> Name
							</label>
							<input
								type='text'
								value={name}
								className='sm:w-96 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
								placeholder='Name'
								onChange={e => setName(e.target.value)}
							/>
						</div>
						<div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
							<label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
								Company Name
							</label>
							<input
								type='text'
								value={companyName}
								className='sm:w-96 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
								placeholder='Your company name'
								onChange={e => setCompanyName(e.target.value)}
							/>
						</div>
						<div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
							<label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
								<span className='text-secondary font-extrabold'>*</span> Email
							</label>
							<input
								type='text'
								value={email}
								className='sm:w-96 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
								placeholder='Email'
								onChange={e => setEmail(e.target.value)}
							/>
						</div>
						<div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
							<label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
								<span className='text-secondary font-extrabold'>*</span> Country
							</label>
							<input
								type='text'
								value={country}
								className='sm:w-96 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
								placeholder='Country'
								onChange={e => setCountry(e.target.value)}
							/>
						</div>
						<div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
							<label className='flex flex-row sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
								<span className='text-secondary font-extrabold mr-2'>*</span> Phone
							</label>
							<div>
								<PhoneInput
									country={'jp'}
									value={phone}
									onChange={handeler}
									// containerStyle={{ marginLeft: '120px' }}
									inputStyle={{ width: size <= 425 ? '320px' : '383px', height: size <= 425 ? '28px' : '40px', border: '1px solid #000' }}
									buttonStyle={{ border: '1px solid #000' }}
								/>
							</div>
						</div>
						<div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
							<label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
								Comment
							</label>
							<textarea
								type='text'
								value={Comment}
								className='sm:w-96 h-36 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
								placeholder='Please provide as much detail as possible so that we can help you better'
								onChange={e => setComment(e.target.value)}
							/>
						</div>
						<div className='flex flex-row justify-center items-center mt-10'>
							<div
								className={`border-2 border-solid border-secondary mr-2 rounded w-6 h-6`}
								onClick={() => setReceiveNews(!receiveNews)}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={3}
									stroke='#fff'
									className={`w-5 h-5 ${receiveNews ? 'block bg-secondary' : 'hidden'}`}
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M4.5 12.75l6 6 9-13.5'
									/>
								</svg>
							</div>
							<div>Receive news, coupons and special deals</div>
						</div>
						<div className='flex flex-row justify-center items-center mt-10'>
							<div
								className={`border-2 border-solid border-secondary mr-2 rounded w-6 h-6`}
								onClick={() => setPrivacyPolicy(!privacyPolicy)}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={3}
									stroke='#fff'
									className={`w-5 h-5 ${privacyPolicy ? 'block bg-secondary' : 'hidden'}`}
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M4.5 12.75l6 6 9-13.5'
									/>
								</svg>
							</div>
							<div>* I agree to <a href='/privacy-policy' className='text-secondary font-semibold'>Privacy Policy</a></div>
						</div>
					</div> : null}
					{!show ? <div className='mb-3'><Button title='Send' d={sendD} textColor='text-white bg-secondary' bgColor='bg-secondary' deactive={!privacyPolicy} onClickHandeler={submitHandeler} /></div> : null }
					{show ? <div className='my-10'>{!isLoading && !isError && data?.isSuccess ? null : <PulseLoader color="var(--secondary-color)" size={15} />}</div> : null}
				</div>
			</div>
		</Public>
	)
}

export default Inquiry

