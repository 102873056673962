import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import 'react-phone-input-2/lib/style.css'
import Button from '../../../components/Buttons/Button'
import Public from '../../../Layout/Public'
import { useSubmitContactUsMutation } from '../../../features/carsPage/carsPageApi'
import { setCurrentPage } from './../../../features/other/otherSlice'
// import useWindowSize from '../../../hooks/useWindowSize'
import PulseLoader from 'react-spinners/PulseLoader'

const ContactUs = () => {
    // const [size] = useWindowSize()
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [descriptions, setDescriptions] = useState('')
    const [show, setShow] = useState(false)
    const sendD = 'M10.5 13.5 21 3M10.627 13.828l2.628 6.758c.232.596.347.893.514.98a.5.5 0 0 0 .462 0c.167-.086.283-.384.515-.979L21.336 3.7c.21-.537.315-.805.258-.977a.5.5 0 0 0-.316-.316c-.172-.057-.44.048-.978.257L3.413 9.253c-.595.233-.893.349-.98.516a.5.5 0 0 0 0 .461c.087.167.385.283.98.514l6.758 2.629c.121.047.182.07.233.106a.5.5 0 0 1 .116.117c.037.051.06.111.107.232Z'
    const [submitContactUs, { data, isLoading, isError }] =
        useSubmitContactUsMutation() || {}

    useEffect(() => {
        dispatch(setCurrentPage({
            currentPage: 'about-us'
        }))
    }, [])
    const cleanStates = () => {
        setName('')
        setEmail('')
        setSubject('')
        setDescriptions('')
    }

    const submitHandeler = () => {
        setShow(true)
        const formData = new FormData()
        formData.append('Name', name)
        formData.append('Email', email)
        formData.append('Subject', subject)
        formData.append('Description', descriptions)

        submitContactUs({ formData })
        // cleanStates()
    }

    const saveAlert = () => {
        alert('Please fill all the fields')
    }

    return (
        <Public>
            <div className='w-full h-full mt-20'>
                <div className='flex flex-col justify-center items-center w-full'>
                    <div className='flex flex-row justify-center items-center mt-10'>
                        <div className='flex justify-center items-center bg-green-600 w-20 h-20 rounded-full'><span className='text-xs text-white'>Information</span></div>
                        <div className='bg-green-600 w-20 sm:w-48 h-1'></div>
                        <div className={`flex justify-center items-center ${data?.isSuccess ? 'bg-secondary' : 'bg-green-600'} w-20 h-20 rounded-full`}><span className='text-xs text-white'>Complete</span></div>
                    </div>
                    {show ? <div className='my-10'>{!isLoading && !isError && data?.isSuccess ? <div className='text-4xl text-secondary font-bold'>{data.message}</div> : <div className='text-4xl text-secondary font-bold'>Sending Your Message</div>}</div> : null}
                    {!show ? <div className='text-4xl text-secondary font-bold text-center mt-6 w-full'>Enter Your Queries</div> : null}
                    {!show ? <div className='flex flex-col my-6 sm:my-16'>
                        <div className='flex flex-col sm:flex-row justify-between w-full'>
                            <label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
                                <span className='text-secondary font-extrabold'>*</span> Name
                            </label>
                            <input
                                type='text'
                                value={name}
                                className='sm:w-96 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
                                placeholder='Name'
                                onChange={e => setName(e.target.value)}
                            />
                        </div>

                        <div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
                            <label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
                                <span className='text-secondary font-extrabold'>*</span> Email
                            </label>
                            <input
                                type='text'
                                value={email}
                                className='sm:w-96 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
                                placeholder='Email'
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        <div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
                            <label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
                                <span className='text-secondary font-extrabold'>*</span> Subject
                            </label>
                            <input
                                type='text'
                                value={subject}
                                className='sm:w-96 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
                                placeholder='Subject'
                                onChange={e => setSubject(e.target.value)}
                            />
                        </div>

                        <div className='flex flex-col sm:flex-row justify-between w-full mt-3 sm:mt-14'>
                            <label className='sm:mr-20 text-xl sm:text-3xl font-bold text-gray-600'>
                                <span className='text-secondary font-extrabold'>*</span> Descriptions
                            </label>
                            <textarea
                                type='text'
                                value={descriptions}
                                className='sm:w-96 h-36 focus:outline-none pl-2 border border-solid border-gray-900 rounded'
                                placeholder='Please provide as much detail as possible so that we can help you better'
                                onChange={e => setDescriptions(e.target.value)}
                            />
                        </div>

                    </div> : null}
                    {!show ? <div className='mb-3'><Button title='Send' d={sendD} textColor='text-white bg-secondary' bgColor='bg-secondary' onClickHandeler={name && email && subject && descriptions ? submitHandeler : saveAlert} /></div> : null}
                    {show ? <div className='my-10'>{!isLoading && !isError && data?.isSuccess ? null : <PulseLoader color="var(--secondary-color)" size={15} />}</div> : null}
                </div>
            </div>
        </Public>
    )
}

export default ContactUs

