// import useWindowSize from './../../../hooks/useWindowSize';

function AtoZ() {

	const printAtoZ = () => {
		let arr = []
		for (let i = 65; i <= 90; i++) {
			const item = String.fromCharCode(i)
			arr.push(item)

		}

		return (
			<div className='flex flex-col items-center my-4 px-4 w-full'>
				<div className='flex flex-row justify-center items-center w-screen mx-9'>
					{arr.map((alfa, index) => <div key={index} className='flex flex-row justify-around items-center text-sm lg:text-xl font-medium text-gray-300 w-auto'><a href={'#' + alfa}><span className=" cursor-pointer hover:text-secondary">{alfa}</span></a><span className='text-md lg:text-2xl mx-1'>|</span></div>)}
					<span className='text-sm lg:text-xl font-medium text-gray-300'>Others</span>
				</div>
				<div className='bg-gray-300 mt-1 lg:mt-3 h-1 w-full'></div>
			</div>
		)
	}

	return (
		<>
			{printAtoZ()}
		</>
	)
}

export default AtoZ
