import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login1';
// import Login2 from "./components/Login2";
import CreateManufacturer from './routes/AdminRoute/CreateManufacturer';
import InquiryReports from './routes/AdminRoute/Reports/InquiryReports';
import UsersReports from './routes/AdminRoute/Reports/UsersReports';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import useAuthCheck from './hooks/useAuthCheck';
import CreateCarInfo from './routes/AdminRoute/CreateCarInfo';
import HomePage from './routes/PublicRoute/Home';
import CarManufacturers from './routes/PublicRoute/CarManufacturers';
import CarModels from './routes/PublicRoute/CarModels';
import CarList from './routes/PublicRoute/CarList';
import CarDetails from './routes/PublicRoute/CarDetails';
import PrivacyPolicy from './routes/PublicRoute/PrivacyPolicy';
import FAQ from './routes/PublicRoute/FAQ';
import Inquiry from './routes/PublicRoute/Inquiry';
import AboutUs from './routes/PublicRoute/AboutUs';
import AdminCarList from './routes/AdminRoute/UpdateCar/AdminCarList';
import ChangePassword from './components/ChangePassword';
import EditSingleCar from './routes/AdminRoute/UpdateCar/EditSingleCar';
import ContactUs from './routes/PublicRoute/ContactUs/Index';
import InsertParts from './routes/AdminRoute/InsertParts/InsertParts';
import CarParts from './routes/PublicRoute/CarParts/CarParts';

function App() {
  const authChecked = useAuthCheck();
  return !authChecked ? (
    <div>Loading...</div>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/car/manufacturers' element={<CarManufacturers />} />
        <Route path='/cars/models' element={<CarModels />} />
        <Route path='/cars/carList/:route' element={<CarList />} />
        <Route path='/cars/car-details' element={<CarDetails />} />
        <Route path='/cars/car-parts' element={<CarParts />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/inquiry' element={<Inquiry />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route
          path='/login'
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        {/* <Route path="/login2" element={<Login2 />} /> */}
        <Route
          path='/admin/change_password'
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path='/admin/create-manufacturer'
          element={
            <PrivateRoute>
              <CreateManufacturer />
            </PrivateRoute>
          }
        />
        <Route
          path='/admin/create-car-info'
          element={
            <PrivateRoute>
              <CreateCarInfo />
            </PrivateRoute>
          }
        />
        <Route
          path='/admin/edit-car/:id'
          element={
            <PrivateRoute>
              <EditSingleCar />
            </PrivateRoute>
          }
        />
        <Route
          path='/admin/cars-list'
          element={
            <PrivateRoute>
              <AdminCarList />
            </PrivateRoute>
          }
        />
        <Route
          path='/admin/inquiry-reports'
          element={
            <PrivateRoute>
              <InquiryReports />
            </PrivateRoute>
          }
        />
        <Route
          path='/admin/users-reports'
          element={
            <PrivateRoute>
              <UsersReports />
            </PrivateRoute>
          }
        />
        <Route
          path='/admin/parts'
          element={
            <PrivateRoute>
              <InsertParts />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
