import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import SingleNewArrival from './SingleNewArrival'



function NewArrivals({data}) {

  return (
    <div className=' w-auto h-auto bg-gray-100 pb-4'>
      <div className='text-2xl font-medium ml-3 lg:ml-5 my-4'>
        <span className='text-secondary'>New Arrivals</span>
      </div>
      <div className="m-5 grid grid-cols-1 sm:grid-cols-4 gap-5">
        {data.carList.slice(0, 5).map((item, i) => <SingleNewArrival key={i} data={item} index={i} />)}
      </div>
      <div className='text-center'>
        <Link to='/cars/carList/new-arrival'>
          <button className='btn w-24 relative inline-flex items-center justify-center overflow-hidden transition-all bg-transparent front-medium rounded-xl border-solid border-2 border-spacing-0 border-secondary md: h-7 md:h-12 mr-2 text-xs md:text-sm mt-1 text-black md:font-medium cursor-pointer hover: group'>
            <div className="w-0 h-0 bg-secondary absolute  ease-out duration-500 transition-all rounded group-hover:w-full group-hover:h-full -z-1"></div>
            <div className="flex flex-row justify-center w-full text-black font-medium mx-2 my-2 transition-colors duration-300 ease-in-out  group-hover:text-white z-10">
              All Arrivals
            </div>
          </button>
        </Link>
      </div>
    </div>
  )
}

export default NewArrivals
