import { useSelector } from 'react-redux'

const Pagination = ({page, handlePage, backwardClick, forwardClick}) => {
  // const dispatch = useDispatch()
  const { pageCount } = useSelector(state => state.cars)

  // const num = Math.ceil(pageCount)
  let pages = [...Array(pageCount).keys()]

  return (
    <div className='flex justify-center items-center max-sm:mt-6'>
      <section className='flex bg-white p-1 rounded-md'>
        <div className='flex gap-2 border-2 rounded-md'>
          <button className='flex justify-center items-center p-1 cursor-pointer' onClick={backwardClick} >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={3.5}
              stroke='currentColor'
              className='w-4 h-4 text-cadmium'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 19.5L8.25 12l7.5-7.5'
              />
            </svg>
          </button>

          {pages.map(p => (
            <div
              className={`${p === page ? 'text-cadmium' : 'text-gray-600'} text-center text-md font-bold ${p === 0 ? 'border-l-2 pl-3' : 'pl-1'} border-r-2 pr-3 py-2 cursor-pointer`}
              key={p}
              onClick={() => handlePage(p)}
            >
              {p + 1}
            </div>
          ))}

          <button className='flex justify-center items-center p-1 cursor-pointer' onClick={forwardClick} >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={3.5}
              stroke='currentColor'
              className='w-4 h-4 text-cadmium'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M8.25 4.5l7.5 7.5-7.5 7.5'
              />
            </svg>
          </button>
        </div>
      </section>
    </div>
  )
}

export default Pagination