import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useLoginMutation } from '../features/auth/authApi'
import backgroundImage from './../assets/car.jpg'

const Login1 = () => {
	const navigate = useNavigate()
	const [userName, setUserName] = useState('')
	const [password, setPassword] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const [login, { data, isLoading, error }] = useLoginMutation()

	const handleSubmit = (e) => {
		e.preventDefault()
		setErrorMessage('')
		login({
			'userName': userName,
			'password': password
		})
	}

	useEffect(() => {

		if (data?.isSuccess) {
			navigate('/admin/create-manufacturer')
		} else {
			setErrorMessage(data?.message)
		}
	}, [data, navigate, error])

	return (
		<div className='grid grid-cols-1 sm:grid-cols-2 h-screen w-full'>
			<div>
				<img
					className='w-full h-full object-cover'
					src={backgroundImage}
					alt='Background Car'
				/>
			</div>
			<div className='bg-gray-200 flex flex-col justify-center'>
				<form className='max-w-[400px] w-full mx-auto bg-white p-4' onSubmit={handleSubmit}>
					<h2 className='text-4xl font-bold text-center py-6'>Idea Car</h2>
					<div className='flex flex-col p-2'>
						<label htmlFor='name'>Username</label>
						<input className='border p-2' type='text' value={userName} placeholder='Name' onChange={(e) => setUserName(e.target.value)} autoComplete='userName' required />
					</div>
					<div className='flex flex-col p-2'>
						<label htmlFor='password'>Password</label>
						<input className='border p-2' type='password' value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} autoComplete='password' required />
					</div>
					{/* <Link to='/admin/create-manufacturer'> */}
					<button className='border w-full mt-6 py-2 bg-gray-900 hover:bg-gray-700 text-yellow-200' type="submit" disabled={isLoading}>
						Sign in
					</button>
					{/* <Link to='/change_password'>
						<div className='w-full text-right text-sm text-secondary hover:text-nav-black'>Change Password</div>
					</Link> */}
					{/* <div className='flex justify-between'>
						<p className='flex justify-center'>
							<input className='mr-2' type='checkbox' />
							Remember me
						</p>
						<p>Create a new account</p>
					</div> */}
					<div className='text-center'>{errorMessage}</div>
				</form>

			</div>
		</div>
	)
}

export default Login1
