import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Public from './../../../Layout/Public'
import { useGetAllManufacsOriginWiseMutation } from '../../../features/carsPage/carsPageApi'
import { setCurrentPage } from './../../../features/other/otherSlice'
import {
  originWiseManufacs,
  removeManufacturers,
  removeModels
} from '../../../features/carsPage/carsPageSlice'
// import SearchBoxBG from './../../../components/SearchBox/SearchBoxBG'
import SearchBar from './../../../components/SearchBar/SearchBar'
import Title from './Title'
import OriginWiseBlocks from './OriginBlocks'
import PulseLoader from 'react-spinners/PulseLoader'
import SearchBox from '../../../components/SearchBox/SearchBox'
import BgImage from './../../../assets/bg_suv.jpg'

const CarManufacturers = () => {
  const dispatch = useDispatch()
  const [clicked, setClicked] = useState(false)
  const [manufacsOriginWise, setManufacsOriginWise] = useState([])
  const { manufacturersId } = useSelector(state => state.cars)
  const [getAllManufacsOriginWise, { data, isLoading, isError }] =
    useGetAllManufacsOriginWiseMutation() || {}
  useEffect(() => {
    dispatch(setCurrentPage({
      currentPage: 'cars'
    }))
    dispatch(removeManufacturers())
    getAllManufacsOriginWise()
  }, [])

  useEffect(() => {
    if (!isLoading && !isError && data?.data) {

      setManufacsOriginWise(data.data)

    }
  }, [dispatch, isLoading, isError, data])

  // const clickHandeler = () => {
  //   dispatch(removeModels())
  // }

  const clearFilterHandeler = () => {
    dispatch(removeManufacturers())
    setClicked(!clicked)
  }

  return (
    <Public>
      <div
        className={`flex flex-col justify-center items-center h-screen`}
        style={{
          backgroundImage: `url(${BgImage})`,
          // width: '100%',
          // height: '33%',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <SearchBox
          bgColor='bg-white'
          searchTitleBorderColor='border-red-500'
          searchTitleColor='text-black'
          inputColor='bg-neutral-100'
          buttonColor='bg-neutral-200'
          textColor='text-white'
          numColor='text-white'
          searchBoxOpacity='bg-opacity-60'
        />
      </div>
      <div className='w-auto h-auto mx-10'>
        <div className='max-sm:hidden'>
          <div id="decoration" className='indexClass'>
            <div id="pill-1" className="pill"></div>
            <div id="pill-2" className="pill"></div>
            <div id="pill-3" className="pill"></div>
            <div id="pill-4" className="pill"></div>
            <div id="pill-5" className="pill"></div>
            <div id="pill-6" className="pill"></div>
            <div id="pill-7" className="pill"></div>
            <div id="pill-8" className="pill"></div>
            <div id="pill-9" className="pill"></div>
            <div id="pill-10" className="pill"></div>
          </div>
        </div>
        <Title />
        {manufacturersId.length > 0 ? <SearchBar link='/cars/models' clearHandeler={clearFilterHandeler} buttonActiveLength={manufacturersId.length} /> : null}
        {isLoading ? (
          <div className='flex justify-center items-center mb-3'>
            <span className='flex justify-center items-center my-10 w-full'><PulseLoader color="var(--secondary-color)" size={25} /></span>
          </div>
        ) : (
          <OriginWiseBlocks manufacsOriginWise={manufacsOriginWise} clickedUnCheck={clicked} />
        )}
      </div>

    </Public>
  )
}

export default CarManufacturers
