import SingleModel from './SingleModel'

const LoopingBlock = ({data, clickedUnCheck}) => {

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row border-b-2'>
        <span className='text-4xl font-bold text-black' id={data.modelInitials}>
          {data.modelInitials}
        </span>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-4'>
        {data.models.map((i, index) => (
          <SingleModel key={index} item={i} trigger={clickedUnCheck} />
        ))}
      </div>
    </div>
  )
}

export default LoopingBlock
