import { Link } from 'react-router-dom'

const Button = ({ d, title, bgColor, textColor, fontSize, hover,  active, onClickHandeler, link, iconSize, width, height, iconStrokeWidth, deactive }) => {

  return (
    <Link to={deactive ? '#' : link}
      className={`${deactive ? 'cursor-default' : ''} flex flex-row justify-center items-center ${bgColor ? bgColor : 'bg-primary'} ${active} ${hover ? hover : 'hover:bg-cadmium'} ${width ? width : 'w-32'} ${height ? height : 'h-12'} mr-2 text-xl ${textColor} ${fontSize ? fontSize : 'font-bold'} rounded`}
      onClick={deactive ? null : onClickHandeler}
      // disabled={deactive ? true : false}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={`${iconStrokeWidth ? iconStrokeWidth : 3}`}
        stroke='currentColor'
        className={`${iconSize ? iconSize : 'w-5 h-5'} mr-2`}
      >
        <path strokeLinecap='round' strokeLinejoin='round' d={d} />
      </svg>
      {title}
    </Link>
  )
}

export default Button
