import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userLoggedIn } from '../features/auth/authSlice'

function useAuthCheck() {
	const dispatch = useDispatch()
	const [authChecked, setAuthChecked] = useState(false)
	useEffect(() => {
		const localAuth = localStorage?.getItem('auth')
		if (localAuth) {
			const auth = JSON.parse(localAuth)
			if (auth?.userName && auth?.token) {
				dispatch(userLoggedIn({
					userName: auth.userName,
					token: auth.token
				}))
			}
		}
		setAuthChecked(true)
	}, [dispatch])
	
	return authChecked
}

export default useAuthCheck