import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import Admin from '../../../Layout/Admin'
import { useGetExistingCarPartsMutation, useInsertCarPartsMutation } from './../../../features/carInfo/carInfoApi'
import PulseLoader from 'react-spinners/PulseLoader'

function InsertParts() {
	const imagesRef = useRef()
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [images, setImages] = useState([])
	const auth = JSON.parse(localStorage.getItem('auth'))

	const [getExistingCarParts, { data: existingData, isLoading: existingDataLoading, isError: existingDataError }] =
		useGetExistingCarPartsMutation() || {}
	const [insertCarParts, { data, isLoading, isError, error }] =
		useInsertCarPartsMutation() || {}
	// console.log(data);
	useEffect(() => {
		const formData = new FormData()
		formData.append('userName', `${auth.userName}`)
		getExistingCarParts({ formData })
	}, [])

	useEffect(() => {
		if (!existingDataLoading && !existingDataError && existingData?.data?.parts_Title) {
			console.log(existingData);
			setTitle(existingData.data?.parts_Title)
			setDescription(existingData.data?.parts_Description)
		}
	}, [existingDataLoading])

	useEffect(() => {
		if (!isLoading && !isError && !error && data?.isSuccess) {
			alert(data.message)
		}
	}, [isLoading])

	const handleSubmit = e => {
		e.preventDefault()

		const formData = new FormData()
		formData.append('userName', `${auth.userName}`)
		formData.append('Parts_Title', title)
		formData.append('Parts_Description', description)
		const objectLength = Object.keys(images).length
		for (let i = 0; i < objectLength; i++) {
			formData.append('Images', images[i])
		}

		var object = {}
		formData.forEach((value, key) => {
			// Reflect.has in favor of: object.hasOwnProperty(key)
			if (!Reflect.has(object, key)) {
				object[key] = value
				return
			}
			if (!Array.isArray(object[key])) {
				object[key] = [object[key]]
			}
			object[key].push(value)
		})

		insertCarParts({ formData })

		imagesRef.current.value = ''
		setTitle('')
		setDescription('')
	}
	return (
		<Admin>
			{!isLoading ? <div className='flex justify-center items-center w-auto my-6 mx-auto max-w-3xl'>
				<form
					className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none'
					onSubmit={handleSubmit}
				>
					{/*header*/}
					<div className='flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t'>
						<h3 className='text-xl text-gray-700 font-semibold'>
							Add car parts info
						</h3>
					</div>
					{/*body*/}
					<div className='relative p-6 flex-auto'>
						<div className='flex mb-7'>
							<label className='mr-3 text-gray-800 font-bold'>
								Part's name:
							</label>
							<input
								type='text'
								className='bg-gray-100 p-1'
								value={title}
								placeholder="Part's name"
								onChange={e => setTitle(e.target.value)}
								required
							/>
						</div>
						<div className='flex mb-7'>
							<label className='mr-3 text-gray-800 font-bold'>
								Part's description:
							</label>
							<input
								type='text'
								className='bg-gray-100 p-1'
								value={description}
								placeholder="Part's description"
								onChange={e => setDescription(e.target.value)}
								required
							/>
						</div>
						<div>
							<label className='mr-3 text-gray-800 font-bold'>
								Part's images:
							</label>
							<input
								type='file'
								multiple
								ref={imagesRef}
								onChange={e => setImages(e.target.files)}
							/>
							<br />
							<span className='text-red-600 text-sm'>Minimum Image Dimentions: Width: 640 Pixels, Height: 428 Pixels</span>
						</div>
					</div>
					{/*footer*/}
					<div className='flex justify-end px-6 py-2 border-t border-solid border-gray-300 rounded-b'>
						{/* <button
							className='bg-red-700 hover:bg-red-800 px-3 py-1 text-white font-bold border-red-900 border-b-2 rounded'
							type='button'
							onClick={() => setShowNewForm(false)}
						>
							Close
						</button> */}
						<button
							className='bg-green-700 hover:bg-green-800 px-3 py-1 text-white font-bold border-green-900 border-b-2 rounded'
							type='submit'
						>
							Save
						</button>
					</div>
				</form>
			</div>
				:
				<div className='flex justify-center items-center my-10 w-full'>
					<PulseLoader color="#374151" size={25} />
				</div>
			}
		</Admin>
	)
}

export default InsertParts
