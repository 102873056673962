import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useGetModelCountByManufacIDMutation } from '../../features/homePage/homePageApi'
import { storeModelCountByManufacID, removeModelCountByManufacID, clearSearchEnums } from '../../features/homePage/homePageSlice'
import { removeSearchItems, setSearchItems } from '../../features/carsPage/carsPageSlice'
// import BgImage from './../../assets/bg_suv.jpg'
import MultiRangeSlider from 'multi-range-slider-react'
import Button from '../Buttons/Button'

const SearchBox = ({
	bgColor,
	inputColor,
	buttonColor,
	textColor,
	numColor,
	searchTitleBorderColor,
	searchTitleColor,
	searchTitleBoxOpacity,
	searchBoxOpacity
}) => {
	const dispatch = useDispatch()
	const { modelCountByManufacID, searchEnums } = useSelector(state => state.homePage)
	const { manufacturersId, models } = useSelector(state => state.cars)
	const date = new Date()
	const latestYear = date.getFullYear()
	// const startingPrice = searchEnums.carMinPrice
	// const maxPrice = searchEnums.carMaxPrice
	const [manufacId, setManufacId] = useState(undefined)
	const [model, setModel] = useState(undefined)
	const [minYearValue, setMinYearValue] = useState(1971)
	const [maxYearValue, setMaxYearValue] = useState(latestYear)
	const [minPriceValue, setMinPriceValue] = useState('')
	const [maxPriceValue, setMaxPriceValue] = useState('')
	const searchD =
		'M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'

	const [
		getModelCountByManufacID,
		{ data: modelCount, isLoading: modelCountLoading, isError: modelCountError }
	] = useGetModelCountByManufacIDMutation() || {}

	const manufacIdHandeler = e => {
		e.preventDefault()
		const id = parseInt(e.target.value)
		setManufacId(id)
		const formData = new FormData()
		formData.append('Id', id)
		getModelCountByManufacID({ formData })
	}

	useEffect(() => {
		dispatch(removeModelCountByManufacID())
		dispatch(removeSearchItems())
	}, [])

	useEffect(() => {
		if (!modelCountLoading && !modelCountError && modelCount?.data) {
			dispatch(
				storeModelCountByManufacID({
					modelCountByManufacID: modelCount.data
				})
			)
		}
	}, [dispatch, modelCountLoading, modelCountError, modelCount])

	const handleYearInput = e => {
		setMinYearValue(e.minValue)
		setMaxYearValue(e.maxValue)
	}
	const handlePriceInput = e => {
		setMinPriceValue(e.minValue)
		setMaxPriceValue(e.maxValue)
	}

	const onClickHandeler = () => {
		dispatch(setSearchItems({
			manufacturer: manufacId,
			model: model,
			minYear: minYearValue,
			maxYear: maxYearValue,
			minPrice: minPriceValue,
			maxPrice: maxPriceValue
		}))
	}

	return (
		<div className={`bg-black ${searchBoxOpacity ? searchBoxOpacity : 'bg-opacity-60'} sm:w-2/3 max-sm:mx-3 shadow-xl shadow-black rounded-xl px-4`}>
			{/* Dropdown */}
			<div className='grid grid-cols-2 sm:grid-cols-2 my-2 gap-4 rounded-xl'>
				{/* Manufacturer Dropdown */}
				<div className='flex flex-col justify-start w-auto'>
					<label
						className={`${textColor ? textColor : 'text-secondary mb-2'
							} text-xs sm:text-lg font-bold`}
					>
						Manufacturer
					</label>
					<select
						name='cars'
						id='cars'
						className={`pl-4 ${inputColor ? inputColor : 'bg-primary'
							} h-11 rounded`}
						onChange={e => manufacIdHandeler(e)}
						value={manufacId}
					>
						<option>
							Select manufacturer
						</option>
						{!searchEnums.hasOwnProperty('manufacCount') ? (
							<option>Loading...</option>
						) : (
							<>
								{searchEnums.manufacCount.map((item, index) => (
									<option key={index} value={item.id}>
										{item.manufacCount}
									</option>
								))}
							</>
						)}
					</select>
				</div>

				{/* Models Dropdown */}
				<div className='flex flex-col justify-start w-auto'>
					<label
						className={`${textColor ? textColor : 'text-secondary mb-2'
							} text-xs sm:text-lg font-bold`}
					>
						Model
					</label>
					<select
						name='cars'
						id='cars'
						className={`pl-4 ${inputColor ? inputColor : 'bg-primary'
							} h-11 rounded`}
						onChange={e => setModel(e.target.value)}
						value={model}
					>
						<option>
							Select model
						</option>
						{/* {modelCountByManufacID.length === 0 ? (
                  <option>Loading...</option>
                ) : (
                  <> */}
						{modelCountByManufacID.map((item, index) => (
							<option key={index} value={item.modelName}>
								{item.modelCount}
							</option>
						))}
						{/* </>
                )} */}
					</select>
				</div>
			</div>

			{/* slider */}
			<div className='grid grid-cols-2 sm:grid-cols-2 my-2 gap-4 rounded-xl'>

				{/* Year */}
				<div className='w-auto md:w-auto'>
					<label
						className={`${textColor ? textColor : 'text-secondary mb-2'
							} text-sm sm:text-lg font-bold`}
					>
						Year
					</label>
					<label className='flex justify-between'>
						<span className={`${numColor ? numColor : 'text-xs sm:text-md text-white'}`}>
							1971
						</span>
						<span className={`${numColor ? numColor : 'text-xs sm:text-md text-white'}`}>
							{latestYear}
						</span>
					</label>
					<MultiRangeSlider
						style={{
							border: 'none',
							boxShadow: 'none'
						}}
						thumbLeftColor={'#FF0000'}
						barInnerColor={'#FFFFFF'}
						thumbRightColor={'#FF0000'}
						label={false}
						ruler={false}
						min={1971}
						max={latestYear}
						step={1}
						minValue={1971}
						maxValue={latestYear}
						onInput={e => {
							handleYearInput(e)
						}}
					/>
				</div>

				{/* price */}
				<div className='w-auto md:w-auto'>
					<label
						className={`${textColor ? textColor : 'text-secondary mb-2'
							} text-sm sm:text-lg font-bold`}
					>
						Price
					</label>
					<label className='flex justify-between'>
						<span className={`${numColor ? numColor : 'text-xs sm:text-md text-white'}`}>
							{!searchEnums.hasOwnProperty('minPrice')
								? 0
								: searchEnums.minPrice}
						</span>
						<span className={`${numColor ? numColor : 'text-xs sm:text-md text-white'}`}>
							{!searchEnums.hasOwnProperty('maxPrice')
								? 11000000
								: searchEnums.maxPrice}
						</span>
					</label>
					<MultiRangeSlider
						style={{
							border: 'none',
							boxShadow: 'none'
						}}
						thumbLeftColor={'#FF0000'}
						barInnerColor={'#FFFFFF'}
						thumbRightColor={'#FF0000'}
						label={false}
						ruler={false}
						min={searchEnums.minPrice}
						max={searchEnums.maxPrice}
						step={1}
						minValue={searchEnums.minPrice}
						maxValue={searchEnums.maxPrice}
						onInput={e => {
							handlePriceInput(e)
						}}
					/>
				</div>

			</div>

			{/* button */}
			<div className='flex flex-col md:flex-row md:justify-between items-center pb-2 w-full'>
				<div className='flex flex-row justify-center sm:justify-start items-end mb-2 md:mb-0 w-full'>
					<span className='text-secondary font-bold text-3xl max-sm:text-xl mr-2 max-sm:mr-1'>
						{!searchEnums.hasOwnProperty('totalCarCount')
							? 0
							: searchEnums.totalCarCount}{' '}
					</span>
					<span className='text-white font-bold max-sm:text-sm uppercase'>used cars in stock</span>
				</div>
				<div className='flex flex-row justify-center sm:justify-end items-center w-full'>
					<Button
						title='Search'
						d={searchD}
						bgColor={manufacturersId.length === 1 && models === 1 ? buttonColor : null}
						hover='hover:bg-slate-200'
						textColor='text-secondary'
						link='/cars/carList/search'
						deactive={(manufacId && model) ? false : true}
						onClickHandeler={onClickHandeler}
					/>
				</div>
			</div>
		</div>
	)
}

export default SearchBox
