import { Link } from 'react-router-dom'
import useScrollHeight from '../../hooks/useScrollHeight'

const InquiryButton = ({ d, title, currentPage }) => {
	const [scrollHeight] = useScrollHeight()
	const alertFunc = () => {
		if (currentPage !== 'car-details') alert('Please select a car first')
	}

	return (
		<Link to={currentPage === 'car-details' ? '/inquiry' : ''} onClick={alertFunc} className="btn relative inline-flex items-center justify-center overflow-hidden transition-all bg-transparent front-medium w-24 rounded-xl border-solid border-2 hover:border-0 border-spacing-0 border-secondary md:w-32 h-7 md:h-12 mr-2 text-xs md:text-sm mt-1 text-white md:font-medium cursor-pointer group">
			<div className="w-0 h-0 bg-secondary absolute ease-out duration-500 rounded group-hover:w-full group-hover:h-full"></div>
			<div className={`flex flex-row justify-center items-center w-full ${currentPage !== 'home' ? 'text-white' : scrollHeight > 0 ? 'text-black' : 'text-white'} transition-colors duration-300 ease-in-out group-hover:text-white z-10`}>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className={`w-3 md:w-5 h-3 md:h-5 md:mr-1 ${currentPage !== 'home' ? 'stroke-white' : scrollHeight > 0 ? 'stroke-black' : 'stroke-white'} group-hover:stroke-white`}>
					<path strokeLinecap="round" strokeLinejoin="round" d={d} />
				</svg>
				{title}
			</div>
		</Link>
	)
}

export default InquiryButton
