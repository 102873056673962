import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { apiSlice } from '../features/api/apiSlice'
import { currencyApiSlice } from '../features/api/currencyApiSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import authSliceReducers from '../features/auth/authSlice'
import carsPageSliceReducers from '../features/carsPage/carsPageSlice'
import homePageSliceReducers from '../features/homePage/homePageSlice'
import filterSliceReducers from '../features/filter/filterSlice'
import otherSliceReducers from '../features/other/otherSlice'
// import otherStateReducers from '../features/otherState/otherStateSlice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [apiSlice.reducerPath, currencyApiSlice.reducerPath]
}

const rootReducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [currencyApiSlice.reducerPath]: currencyApiSlice.reducer,
  auth: authSliceReducers,
  cars: carsPageSliceReducers,
  homePage: homePageSliceReducers,
  filter: filterSliceReducers,
  other: otherSliceReducers,
  // otherState: otherStateReducers
})
const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddlewares =>
    getDefaultMiddlewares().concat(apiSlice.middleware, currencyApiSlice.middleware)
})

export const persistor = persistStore(store)
