import { apiSlice } from '../api/apiSlice';
// import { userLoggedIn } from './reportsSlice';

export const reportsApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllInquiriesRpt: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetAllInquiriesRpt',
				method: 'POST',
				body: data
			})
		}),
		getAllInquiryUsersRpt: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetAllInquiryUsersRpt',
				method: 'POST',
				body: data
			})
		})
	})
})

export const { useGetAllInquiriesRptMutation, useGetAllInquiryUsersRptMutation } = reportsApi