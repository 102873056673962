import { apiSlice } from "../api/apiSlice";

export const manufacturerApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCarCountByManufac: builder.mutation({
			query: () => ({
				url: '/public/api/GetCarCountByManufac',
				method: 'POST'
			}),

		}),
		getNewArrivalCars: builder.mutation({
			query: data => ({
				url: '/public/api/GetNewArrivalCars',
				method: 'POST',
				body: data.formData,
			})
		}),
		getSearchEnums: builder.mutation({
			query: () => ({
				url: '/public/api/GetSearchEnums',
				method: 'POST'
			})
		}),
		getModelCountByManufacID: builder.mutation({
			query: data => ({
				url: '/public/api/GetModelCountByManufacID',
				method: 'POST',
				body: data.formData,
			})
		})
	})
})

export const { useGetCarCountByManufacMutation, useGetNewArrivalCarsMutation, useGetSearchEnumsMutation, useGetModelCountByManufacIDMutation } = manufacturerApi