import React from 'react'

const Title = () => {
  return (
    <div className='text-2xl font-bold my-4'>
      <span className='text-secondary mr-1'>Search By Manufacturers</span>
      <span className='text-gray-900 text-xs font-light'>(Max 10 choices)</span>
    </div>
  )
}

export default Title
