import Button from './../Buttons/Button'

const SearchBar = ({clickHandel, link, clearHandeler, buttonActiveLength}) => {
  const searchD = 'M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'

  return (
    <div className={`flex flex-row bg-black rounded-lg my-3 max-sm:mt-28`}>
      <div className='flex justify-end basis-7/12 my-2'>
        <Button
          title='Search'
          d={searchD}
          bgColor='bg-secondary'
          textColor='text-white'
          active='active:bg-red-800'
          onClickHandeler={clickHandel}
          link={link}
          deactive={buttonActiveLength === 0 ? true : false}
        />
      </div>
      <div className='flex justify-end basis-5/12 items-end'>
        <span className='text-white pr-6 pb-2 cursor-pointer hover:font-bold hover:text-secondary active:text-white' onClick={clearHandeler}>
          Clear Filters
        </span>
      </div>
    </div>
  )
}

export default SearchBar
