import { useState } from 'react';
import { Link } from 'react-router-dom';

const ToggleMenu = ({ textColor, currentPage, scrollHeight }) => {
  const [showMenuBar, setShowMenuBar] = useState(false);
  return (
    <div className='mt-2'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className={`w-6 h-6 block md:hidden ${
          currentPage === 'home'
            ? scrollHeight > 0
              ? 'text-secondary'
              : 'text-white'
            : 'text-white'
        }`}
        onClick={() => setShowMenuBar(!showMenuBar)}
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d={
            !showMenuBar
              ? 'M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25'
              : 'M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12'
          }
        />
      </svg>
      <div
        className={`${
          showMenuBar ? null : 'hidden'
        } fixed top-20 left-72 w-28 h-48 z-10`}
        id='exampleModalSm'
        tabIndex='-1'
      >
        <div
          className={`relative flex flex-col w-full pointer-events-auto ${
            currentPage === 'home' ? 'bg-primary' : 'bg-nav-black'
          } bg-clip-padding rounded-md outline-none text-current border-2 border-secondary`}
        >
          <div className='flex flex-col items-center justify-between py-2 w-full'>
            <div className='flex flex-col'>
              <Link to='/'>
                <div
                  className={`${
                    currentPage === 'home' ? 'text-secondary' : textColor
                  } text-sm text-center font-bold hover:text-secondary`}
                >
                  Home
                </div>
              </Link>
              <Link to='/car/manufacturers'>
                <div
                  className={`${
                    currentPage === 'cars' ? 'text-secondary' : textColor
                  } text-sm text-center font-bold hover:text-secondary`}
                >
                  Cars
                </div>
              </Link>
              <Link to='/cars/car-parts'>
                <div
                  className={`${
                    currentPage === 'cars' ? 'text-secondary' : textColor
                  } text-sm text-center font-bold hover:text-secondary`}
                >
                  Car parts
                </div>
              </Link>
              <Link to='/contact-us'>
                <div
                  className={`${
                    currentPage === 'cars' ? 'text-secondary' : textColor
                  } text-sm text-center font-bold hover:text-secondary`}
                >
                  Contact Us
                </div>
              </Link>
              <Link to='/about-us'>
                <div
                  className={`${
                    currentPage === 'about-us' ? 'text-secondary' : textColor
                  } text-sm font-bold text-center hover:text-secondary`}
                >
                  About Us
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleMenu;
