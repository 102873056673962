import { useState } from 'react'

const SingleQuesBlock = ({item}) => {
	const [open, setOpen] = useState(false)
	// const handleOpen = (id) => {
	// 	setId(id)
	// 	setOpen(!open)
	// }
	return (
		<div className='flex flex-row justify-start items-start w-full'>
			<div className='flex flex-row items-center'>
				<div>{`Q.${item.id + 1}`}</div>
			</div>
			<div className='flex flex-col w-full'>
				<div className='flex flex-row justify-start w-full items-center'>
					<div className='ml-6'>{item.ques}</div>
					<div className='ml-6 cursor-pointer text-xl text-secondary font-bold' onClick={() => setOpen(!open)}>+</div>
				</div>
				{open ?
					<div className={`bg-white ml-6 p-4 rounded`}>
						<div className='text-justify mb-1'>{item.ans1}</div>
						<div className='text-justify mb-1'>{item.ans2}</div>
						<div className='text-justify'>{item.ans3}</div>
						<div className='text-justify'>{item.ans4}</div>
						<div className='text-justify'>{item.ans5}</div>
						<div className='text-justify'>{item.ans6}</div>
						<div className='text-justify'>{item.ans7}</div>
						<div className='text-justify'>{item.ans8}</div>
						<div className='text-justify'>{item.ans9}</div>
						<div className='text-justify'>{item.ans10}</div>
					</div> :
					null
				}
			</div>

		</div>
	)
}

export default SingleQuesBlock
