import { useState } from 'react'
import { Link } from 'react-router-dom'
import useScrollHeight from '../../hooks/useScrollHeight'
import InquiryButton from './../Buttons/InquiryButton'
import Navbar from './../Navbar'
import logo from './../../assets/mainlogo.png'
import { useSelector } from 'react-redux'
import ToggleMenu from '../ToggleMenu/ToggleMenu'


const Header = () => {
  const { currentPage } = useSelector(state => state.other)
  const [scrollHeight] = useScrollHeight()
  const sendInquiryD =
    'M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z'


  return (
    <div className={`${currentPage !== 'home' ? 'bg-nav-black' : scrollHeight > 0 ? 'bg-white transition duration-300' : null} fixed top-0 z-10 flex flex-row flex-start justify-between items-center w-full h-18`}>
      <Link to='/'>
        <img className='w-36 h-24 mx-4 rounded md:ml-20' src={logo} alt='logo' />
      </Link>
      <div className='hidden md:block'>
        <Navbar textColor={`${scrollHeight > 0 ? 'text-black' : 'text-white'}`} currentPage={currentPage} />
      </div>
      <div className='flex flex-col md:flex-row justify-center items-center'>
        <InquiryButton title='Send Inquiry' d={sendInquiryD} currentPage={currentPage} />
        <ToggleMenu textColor={currentPage !== 'home' ? 'text-white' : 'text-nav-black'} currentPage={currentPage} scrollHeight={scrollHeight} />
      </div>
    </div>
  )
}

export default Header
