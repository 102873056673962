import { Link } from 'react-router-dom'
import SearchButton from './../Buttons/SearchButton'
import FooterMenu from './../FooterMenu'
import car from './../../assets/mainlogo.png'
import copyright from './../../assets/copyright-symbol.svg'

function Footer() {
	const sendInquiryD = "M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
	const contactUsD = "M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
	const howToBuyD = "M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"

	return (
		<div className='flex flex-col justify-between bg-black'>
			<div className='m-1 md:m-5 flex flex-row justify-between'>
				<div className='flex flex-col md:flex-row self-center'>
					<Link to='/'>
						<img
							className='w-36 h-24 mx-4 rounded'
							src={car}
							alt='Idea Car'
						/>
					</Link>
					<div className='flex flex-col self-center'>
						{/* <SearchButton title='How to Buy' d={howToBuyD} /> */}
					</div>
				</div>
				<div className='hidden md:block self-center sm:w-3/12 m-5 max-lg:text-xs text-justify text-white'>
					<p>
						IDEACAR is a Japanese company that manages this website. We can offer the perfect car for you from our inventory because we work with second-hand car dealers all over the world. Furthermore, IDEACAR provides a one-stop solution by soliciting customer feedback via SNS communication.
					</p>
				</div>
				<div className='self-center md:w-3/12 text-right'>
					<div className='flex'>
						<div>
							<div className='text-xs sm:text-sm text-bold text-white'><span className='text-secondary'>〒231</span>-0023</div>
							<div className='text-xs sm:text-sm text-bold text-white whitespace-pre-wrap'> 7f 26 YamashitachōNaka Ward</div>
							<div className='text-xs sm:text-sm text-bold text-white whitespace-pre-wrap'>Yokohama</div>
						</div>

						<div className='self-center ml-2 sm::ml-5'>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								xmlSpace="preserve"
								className='w-5 md:w-16 h-5 md:h-16'
							>
								<path
									style={{
										fill: "#ec2727",
									}}
									d="M256 0C142.892 0 51.2 91.692 51.2 204.8 51.2 344.764 170.667 443.733 256 512c85.333-68.267 204.8-167.236 204.8-307.2C460.8 91.692 369.108 0 256 0zm0 256c-28.232 0-51.2-22.968-51.2-51.2s22.968-51.2 51.2-51.2 51.2 22.968 51.2 51.2S284.232 256 256 256z"
								/>
								<path
									style={{
										fill: "#e61919",
									}}
									d="M256 0v153.6c28.232 0 51.2 22.968 51.2 51.2S284.232 256 256 256v256c85.333-68.267 204.8-167.236 204.8-307.2C460.8 91.692 369.108 0 256 0z"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div className='m-7 text-gray-400 border-t'>
			</div>
			<div className='ml-7 mt-2 mb-4 text-gray-400'>Copyright © {new Date().getFullYear()}. All rights reserved. <Link to='/privacy-policy'><span className='underline'>Privacy Policy</span></Link></div>
		</div>


	)
}

export default Footer
