import { useState, useEffect, useRef } from 'react'
// import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLoggedOut } from './../../features/auth/authSlice'
// import axios from 'axios';
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
// import { CSVLink } from 'react-csv'
import Admin from '../../Layout/Admin'
import {
  useGetAllManufacturerMutation,
  useGetManufacturerByIdMutation,
  useInsertManufacturerMutation,
  useUpdateManufacturerMutation,
  useDeleteManufacturerMutation
} from '../../features/manufacturer/manufacturerApi'
import PulseLoader from 'react-spinners/PulseLoader'

const Dasboard = () => {
  const logoRef = useRef()
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const [getAllManufacturer, { data: allManufacturer, isLoading, isError, error }] =
    useGetAllManufacturerMutation() || {}

  const [
    getManufacturerById,
    { data: singleManufacturer, isLoading: singleManufacturerLoading, isError: singleManufacturerError }
  ] = useGetManufacturerByIdMutation() || {}
  const [insertManufacturer, { data: addedManufacturer, isLoading: insertManufacturerLoading, isError: insertManufacturerError }] =
    useInsertManufacturerMutation() || {}
  const [updateManufacturer, { data: updatedManufacturer, isLoading: updateManufacturerLoading, isError: updateManufacturerError }] =
    useUpdateManufacturerMutation() || {}
  const [deleteManufacturer, { data: deletedManufac }] =
    useDeleteManufacturerMutation() || {}
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showNewForm, setShowNewForm] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [toggleUpdateButton, setToggleUpdateButton] = useState(false)
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [origin, setOrigin] = useState('')
  const [file, setFile] = useState('')
  const [filePath, setFilePath] = useState('')
  const auth = JSON.parse(localStorage.getItem('auth'))

  const handleClose = () => {
    setShowCreateForm(false)
    setName('')
    setOrigin('')
    setFile('')
  }

  useEffect(() => {
    const formData = new FormData()
    formData.append('userName', `${auth.userName}`)
    getAllManufacturer({ formData })
  }, [deletedManufac, addedManufacturer, updatedManufacturer])


  useEffect(() => {
    if (!isLoading && isError && error.status === 401) {
      localStorage.clear()
      dispatch(userLoggedOut())
    }
  }, [isLoading])

  useEffect(() => {
    if (!insertManufacturerLoading && !insertManufacturerError && addedManufacturer?.message) {
      alert(addedManufacturer?.message)
    }
  }, [insertManufacturerLoading])

  useEffect(() => {
    if (!updateManufacturerLoading && !updateManufacturerError && updatedManufacturer?.message) {
      alert(updatedManufacturer?.message)
    }
  }, [updateManufacturerLoading])

  useEffect(() => {
    if (!singleManufacturerLoading && !singleManufacturerError && singleManufacturer) {
      dataBindWithUpdateForm()
    }
  }, [singleManufacturerLoading])


  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('userName', `${auth.userName}`)
    formData.append('Name', name)
    formData.append('Origin', origin)
    formData.append('logo', file)

    // var object = {}
    // formData.forEach((value, key) => {
    //   // Reflect.has in favor of: object.hasOwnProperty(key)
    //   if (!Reflect.has(object, key)) {
    //     object[key] = value
    //     return
    //   }
    //   if (!Array.isArray(object[key])) {
    //     object[key] = [object[key]]
    //   }
    //   object[key].push(value)
    // })

    insertManufacturer({ formData })

    setShowCreateForm(false)
    logoRef.current.value = ''
    setName('')
    setOrigin('')
    setFile('')
    setShowNewForm(false)
  }

  const updateFormSetup = id => {
    const formData = new FormData()
    formData.append('userName', `${auth.userName}`)
    formData.append('Id', id)
    getManufacturerById({ formData })
    setShowCreateForm(true)
  }

  const dataBindWithUpdateForm = () => {

    if (singleManufacturer?.isSuccess) {
      // setShowAlert(false)
      setId(singleManufacturer?.data.id)
      setName(singleManufacturer?.data.name)
      setOrigin(singleManufacturer?.data.origin)
      setFilePath(singleManufacturer?.data.file_Path)
      setToggleUpdateButton(true)
    }
  }

  const handleUpdate = e => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('userName', `${auth.userName}`)
    formData.append('Id', id)
    formData.append('Name', name)
    formData.append('Origin', origin)
    formData.append('logo', file)
    formData.append('file_Path', filePath)
    updateManufacturer({ formData })

    setShowCreateForm(false)
    setName('')
    setId('')
    setOrigin('')
    setFile('')
    setFilePath('')
  }

  // const handleDeleteModal = (id) => {
  // 	setShowModal(true)
  // 	setId(id)
  // }
  const handleDelete = id => {
    const formData = new FormData()
    formData.append('userName', `${auth.userName}`)
    formData.append('Id', id)
    deleteManufacturer({ formData })
    setShowModal(false)
  }
  const handleDeleteConfirm = id => {
    let text = 'Are you sure, you want to delete the item?'
    // eslint-disable-next-line no-restricted-globals
    if (confirm(text)) handleDelete(id)
  }

  //Table Data
  const columns = [
    { header: 'Manufacturer', key: 'manufacturer' },
    { header: 'Country', key: 'country' },
    { header: 'logo', key: 'logo' },
    { header: 'Action', key: 'action' }
  ]
  // const data = [
  // 	{
  // 		id: 1,
  // 		manufacturer: 'BMW',
  // 		country: 'America',
  // 		logo: 'https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg'
  // 	},
  // 	{
  // 		id: 2,
  // 		manufacturer: 'BMW',
  // 		country: 'America',
  // 		logo: 'https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg'
  // 	},
  // 	{
  // 		id: 3,
  // 		manufacturer: 'BMW',
  // 		country: 'America',
  // 		logo: 'https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg'
  // 	},
  // 	{
  // 		id: 4,
  // 		manufacturer: 'BMW',
  // 		country: 'America',
  // 		logo: 'https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg'
  // 	},
  // 	{
  // 		id: 5,
  // 		manufacturer: 'BMW',
  // 		country: 'America',
  // 		logo: 'https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg'
  // 	},
  // 	{
  // 		id: 6,
  // 		manufacturer: 'BMW',
  // 		country: 'America',
  // 		logo: 'https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg'
  // 	},
  // 	{
  // 		id: 7,
  // 		manufacturer: 'BMW',
  // 		country: 'America',
  // 		logo: 'https://i.pinimg.com/originals/ac/a8/6e/aca86eb176ab64c634b2619c8086f69d.jpg'
  // 	},
  // ]

  //Export Excel
  const workSheetName = 'Worksheet-1'
  // const workBookName = 'MyWorkBook';
  const workbook = new Excel.Workbook()
  const saveExcel = async () => {
    try {
      // const myInput = document.getElementById(myInputId);
      const fileName = Excel.xlsx

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName)

      // add worksheet columns
      // each columns contains header and its mapping key from data
      const filteredColumns = [columns[0], columns[1]]
      worksheet.columns = filteredColumns

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true }

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 5
        column.alignment = { horizontal: 'center' }
      })

      // loop through data and add each one to worksheet
      allManufacturer?.data.forEach(singleData => {
        const filteredData = {
          name: singleData.name,
          country: singleData.origin
        }
        worksheet.addRow(filteredData)
      })

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach(singleCell => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          }
        })
      })

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer()

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`)
    } catch (error) {
      console.error('<<<ERRROR>>>', error)
      console.error('Something Went Wrong', error.message)
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName)
    }
  }

  let content
  if (isLoading)
    content = (
      <tr>
        <th className='text-lg font-bold px-7 py-10 w-full'>Loading...</th>
      </tr>
    )
  if (!isLoading && isError)
    content = (
      <tr className='flex justify-center'>
        <td className='text-lg p-4 w-full'>Something went wrong!</td>
      </tr>
    )
  if (!isLoading && !isError && allManufacturer?.data.length === 0)
    content = <tr className='col-span-12'>No data found</tr>
  if (!isLoading && !isError && allManufacturer?.data.length > 0) {
    // console.log(allManufacturer)
    content = allManufacturer?.data.map((item, index) => {
      return (
        <tr
          key={index}
          className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'
        >
          {/* <td className='p-4 w-4'>
            <div className='flex justify-center'>
              <input
                id='checkbox-table-1'
                type='checkbox'
                className='w-4 h-4 text-gray-600 bg-gray-100 rounded border-gray-300 focus:ring-gray-100 dark:focus:ring-gray-100 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer'
              />
            </div>
          </td> */}
          <th
            scope='row'
            className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
          >
            {item.name}
          </th>
          <th
            scope='row'
            className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
          >
            {item.origin}
          </th>
          <th className='py-4 px-6 justify-center'>
            <img
              className='w-8 h-8'
              src={process.env.REACT_APP_API_URL + '/Contents' + item.file_Path}
              alt='Logo'
            ></img>
          </th>
          <th className='flex py-4 px-2 justify-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6 cursor-pointer'
              onClick={() => updateFormSetup(item.id)}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
              />
            </svg>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6 ml-10 cursor-pointer'
              onClick={() => handleDeleteConfirm(item.id)}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
              />
            </svg>
          </th>
        </tr>
      )
    })
  }

  return (
    <Admin>
      <div className='m-5'>
        <div>
          <button
            className='bg-gray-700 hover:bg-gray-800 px-4 py-2 text-white font-bold border-gray-900 border-b-2 rounded mr-4'
            type='button'
            onClick={() => setShowNewForm(!showNewForm)}
          >
            Create new manufacturer
          </button>
          <button
            onClick={saveExcel}
            className='bg-gray-700 hover:bg-gray-800 px-4 py-2 text-white font-bold border-gray-900 border-b-2 rounded'
          >
            Export Excel
          </button>
        </div>

        {/*Modal Start*/}
        {showNewForm ?
          <div className='flex justify-center items-center w-auto my-6 mx-auto max-w-3xl'>
            <form
              className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none'
              onSubmit={handleSubmit}
            >
              {/*header*/}
              <div className='flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t'>
                <h3 className='text-xl text-gray-700 font-semibold'>
                  Add manufacturer info
                </h3>
              </div>
              {/*body*/}
              <div className='relative p-6 flex-auto'>
                <div className='flex mb-7'>
                  <label className='mr-3 text-gray-800 font-bold'>
                    Manufacturer's name:
                  </label>
                  <input
                    type='text'
                    className='bg-gray-100 p-1'
                    value={name}
                    placeholder="Manufacturer's name"
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>
                <div className='flex mb-7'>
                  <label className='mr-3 text-gray-800 font-bold'>
                    Manufacturer's origin:
                  </label>
                  <input
                    type='text'
                    className='bg-gray-100 p-1'
                    value={origin}
                    placeholder="Manufacturer's origin"
                    onChange={e => setOrigin(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className='mr-3 text-gray-800 font-bold'>
                    Manufacturer's logo:
                  </label>
                  <input
                    type='file'
                    ref={logoRef}
                    onChange={e => setFile(e.target.files[0])}
                  />
                </div>
              </div>
              {/*footer*/}
              <div className='flex justify-between px-6 py-2 border-t border-solid border-gray-300 rounded-b'>
                <button
                  className='bg-red-700 hover:bg-red-800 px-3 py-1 text-white font-bold border-red-900 border-b-2 rounded'
                  type='button'
                  onClick={() => setShowNewForm(false)}
                >
                  Close
                </button>
                <button
                  className='bg-green-700 hover:bg-green-800 px-3 py-1 text-white font-bold border-green-900 border-b-2 rounded'
                  type='submit'
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
          :
          null
        }
        {showModal ? handleDeleteConfirm : null}
        {showCreateForm ?
          <div className='flex justify-center items-center w-auto my-6 mx-auto max-w-3xl'>
            {/*content*/}
            {!singleManufacturerLoading && !singleManufacturerError && singleManufacturer ?
              <form
                className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none'
                onSubmit={handleSubmit}
              >
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t'>
                  <h3 className='text-xl text-gray-700 font-semibold'>
                    Add manufacturer info
                  </h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <div className='flex mb-7'>
                    <label className='mr-3 text-gray-800 font-bold'>
                      Manufacturer's name:
                    </label>
                    <input
                      type='text'
                      className='bg-gray-100 p-1'
                      value={name}
                      placeholder="Manufacturer's name"
                      onChange={e => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className='flex mb-7'>
                    <label className='mr-3 text-gray-800 font-bold'>
                      Manufacturer's origin:
                    </label>
                    <input
                      type='text'
                      className='bg-gray-100 p-1'
                      value={origin}
                      placeholder="Manufacturer's origin"
                      onChange={e => setOrigin(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label className='mr-3 text-gray-800 font-bold'>
                      Manufacturer's logo:
                    </label>
                    <input
                      type='file'
                      ref={logoRef}
                      onChange={e => setFile(e.target.files[0])}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className='flex justify-between px-6 py-2 border-t border-solid border-gray-300 rounded-b'>
                  <button
                    className='bg-red-700 hover:bg-red-800 px-3 py-1 text-white font-bold border-red-900 border-b-2 rounded'
                    type='button'
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  {toggleUpdateButton ? (
                    <button
                      className='bg-green-700 hover:bg-green-800 px-3 py-1 text-white font-bold border-green-900 border-b-2 rounded'
                      type='submit'
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className='bg-green-700 hover:bg-green-800 px-3 py-1 text-white font-bold border-green-900 border-b-2 rounded'
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  )}
                </div>
              </form>
              :
              <div className='flex justify-center items-center my-10 w-full'>
                <PulseLoader color="#374151" size={25} />
              </div>
            }
          </div>
          :
          null
        }
        {/* {showAlert ? (
          <div
            className='p-4 mb-4 mt-4 text-sm font-bold text-gray-700 bg-gray-300 rounded-lg'
            role='alert'
          >
            <div className='flex justify-center text-lg'>
              Are you sure, you want to update the information?
            </div>
            <div className='flex justify-between px-6 py-2 border-t border-solid border-gray-300 rounded-b'>
              <button
                className='bg-red-700 hover:bg-red-800 px-3 py-1 text-white font-bold border-red-900 border-b-2 rounded'
                type='button'
                onClick={() => setShowAlert(false)}
              >
                No
              </button>
              <button
                className='bg-green-700 hover:bg-green-800 px-3 py-1 text-white font-bold border-green-900 border-b-2 rounded'
                type='button'
                onClick={dataBindWithUpdateForm}
              >
                Yes
              </button>
            </div>
          </div>
        ) : null} */}
        {/*Modal End*/}

        {/*List Start*/}
        <div className='shadow-md sm:rounded-lg mt-7'>
          <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
            <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                {/* <th scope='col' className='py-3 px-6 text-center'>
                  Check
                </th> */}
                <th scope='col' className='py-3 px-6 text-center'>
                  Manufacturer's name
                </th>
                <th scope='col' className='py-3 px-6 text-center'>
                  Country
                </th>
                <th scope='col' className='py-3 px-6'>
                  Logo
                </th>
                <th scope='col' className='py-3 px-2 text-center'>
                  Action
                </th>
              </tr>
            </thead>

            <tbody>{content}</tbody>
          </table>
        </div>
        {/*List End*/}
      </div>
    </Admin>
  )
}

export default Dasboard
