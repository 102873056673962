import { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import youtubeImage from './../../../assets/images/youtube.webp'
// import VideoThumbnail from 'react-video-thumbnail'
// import useWindowSize from './../../../hooks/useWindowSize'

const Image = ({ featuredImage, images, videos }) => {
  const [show, setShow] = useState(false)
  // const [size] = useWindowSize()
  return (
    <div className='flex flex-col w-full'>
      <Carousel
        showArrows={true}
        autoPlay={true}
        interval={3000}
        infiniteLoop={true}
        centerMode={true}
        centerSlidePercentage={100}
        // width={size<370 ? 240 : size<420 ? 295 : null}
        // showThumbs={size < 1020 ? false : true}
        showThumbs={false}
      // thumbWidth={40}
      >

        {images.map((image, i) =>
          <div key={i}>
            <img src={process.env.REACT_APP_API_URL + '/Contents' + image} alt='img' />
          </div>
        )}

      </Carousel>

      <div className='mt-2'>
        <img src={youtubeImage} alt='#' className='w-14 h-12 cursor-pointer' onClick={() => setShow(!show)} />
        {show ?
          <div className='flex flex-row gap-2'>
            {videos.map(video =>
              <a href={video} target='_blank' rel='noreferrer'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-9 h-9 mb-2 cursor-pointer">
                  <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                </svg>
              </a>
            )}
          </div>
          :
          null
        }
      </div>
    </div>
  )
}

export default Image
