import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Public from '../../../Layout/Public'
import { useGetCarPartsMutation } from './../../../features/carsPage/carsPageApi'
import { setCurrentPage } from './../../../features/other/otherSlice'

function CarParts() {
	const dispatch = useDispatch()
	const [getCarParts, { data, isLoading, isError }] = useGetCarPartsMutation() || {}

	useEffect(() => {
		dispatch(setCurrentPage({
			currentPage: 'car-parts'
		}))
		getCarParts()
	}, [])

	return (
		<Public>
			{!isLoading && !isError && data?.isSuccess ?
				<div className='flex flex-col items-center mt-28 pb-4'>
					<div className='text-secondary text-4xl font-bold p-2 text-justify'>{data.data.parts_Title}</div>
					<div className='text-gray-700 text font-bold p-4 text-justify'>{data.data.parts_Description}</div>
					<div className='grid grid-cols-autofit gap-4 w-11/12'>
						{data.data.imageNamesList.map((image, i) => <img className='scale-100 w-auto h-96 shadow-inner rounded-xl border-4 hover:scale-110 hover:ease-in hover:duration-500 hover:overflow-hidden' key={i} src={process.env.REACT_APP_API_URL + '/Contents' + image} alt='img' />)}
					</div>
				</div>
				:
				null
			}
		</Public>
	)
}

export default CarParts
