
const SingleSpecInfo = ({ title, data, singleCarData }) => {

  return (
    <div className='mx-5 mt-2'>
      <div className='flex flex-col justify-start'>
        <span className='text-xl text-black2 font-bold w-full leading-8 mb-4'>{title}</span>
        <div className="flex flex-row flex-wrap gap-2">
          {data.map(item => (
            <span className="border border-gray-300 bg-white p-2 rounded-xl  drop-shadow-sm shadow-inner flex flex-row place-self-center">
              <span>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#000"
                  className="w-6 h-6"
                >
                  <g stroke="#D22B2B" strokeWidth={2}>
                    <path d="m16.516 8.935-5.456 5.767-2.964-2.804" />
                    <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                  </g>
                </svg>
              </span>
              <span key={item.id} className='flex justify-center items-center px-3 rounded-sm'>
                <span className='text-md font-semibold text-black2'>
                  {item.names === 'Seating Capacity' ? <span className="flex flex-col justify-center"><span>Seating Capacity</span><span className="text-center">{singleCarData.seatingCapacity}</span></span> : item.names}
                </span>
              </span>
            </span>
          ))}
        </div>
      </div>
      <div className=' border-b border-gray-300 mt-4 mb-2'></div>
    </div>
  )
}

export default SingleSpecInfo
