import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Public from './../../../Layout/Public'
import { setCurrentPage } from './../../../features/other/otherSlice'

const PrivacyPolicy = () => {
  const dispatch = useDispatch()

	useEffect(() => {
    dispatch(setCurrentPage({
      currentPage: 'privacy-policy'
    }))
  }, [])


	return (
		<Public>
			<div className='bg-gray-200 p-4 mt-32 mx-8 mb-8'>
				<div className='text-xl text-center font-bold'>Overseas Residents' Privacy Statement</div>
				<div className='text-end text-red-600'>Founded on January 1st, 2023</div>
				<div className='text-end text-red-600'>Updated on January 1st, 2023</div>
				<div className='text-justify px-8 pt-8'>We at Ideacar Co., Ltd. value your interest in our services and your visit to our websites. We take the protection of personal information of data subjects seriously under the terms and conditions stated in this Privacy Policy for Overseas Residents (this "Privacy Policy") and the terms and conditions stated in our General Privacy Policy. These countries and regions are not Japan.</div>
				<div className='text-justify px-8 pt-2'>This privacy policy shall take precedence over the general privacy policy in the event of any conflict or inconsistency.</div>
				<div className='text-justify px-8 pt-2'>Ideacar Co., Ltd. is in charge of managing the personal information provided through the Ideacar website and responding to your requests (including concluding a used vehicle sales contract with you) using that personal information. The purposes and methods of processing personal data provided through this website are decided upon by Ideacar Co., Ltd.</div>
				<div className='text-justify px-8 pt-2'>Regarding the protection of personal data, we abide by all applicable laws and rules. All personal information that we collect will only be used for the purposes described in this document.</div>
				<div className='text-center px-8 py-4 font-bold'>Categories of personal data, their uses, and the legal bases for collection and processing</div>
				<div className='px-10'>
					<table className='table-auto'>
						<thead>
							<tr className='border-2 border-black'>
								<th style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >No</th>
								<th style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >Personal Data Categories</th>
								<th style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >Source Classifications</th>
								<th style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >Useful Information</th>
								<th style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >Legal Reasons</th>
								<th style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >Third-Party Recipients of Personal Data</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >1</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >Name,</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >address,</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >phone number,</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' > and email address</p>
								</td>
								<td style={{ border: '2px solid #000', textAlign: 'center' }} className='max-sm:text-xs' >Data Subjects himself or herself</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >1. To respond to your questions about our services.</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >2. To carry out your requests for our services.</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >3. To carry out contractual obligations.</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >4. To provide information about our services.</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >5. To provide or improve our services, as well as to create new ones.</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >6. To implement marketing activities.</p>
								</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Contract performance</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Legitimate interest</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Consent</p>
								</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Freight forwarders</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Shipping companies</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Courier Service Companies</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Clearing Agents</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Our Distributors</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Service Providers who assist us with personal data management</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- External Specialists who are bound by confidentiality obligations under applicable laws and regulations, such as lawyers or tax accountants</p>
									<p style={{ textAlign: 'center' }} className='max-sm:text-xs' >- Officers and employees of our group companies managing personal Information for our company</p>
								</td>
							</tr>
							<tr>
								<td style={{ border: '2px solid #000', textAlign: 'center' }}>2</td>
								<td style={{ border: '2px solid #000', textAlign: 'center' }}>Bank Account Details</td>
								<td style={{ border: '2px solid #000', textAlign: 'center' }}>Data Subjects himself or herself</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }}>1. To carry out your requests for our services.</p>
									<p style={{ textAlign: 'center' }}>2. to carry out contractual obligations</p>
								</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }}>- Contract fulfilment</p>
									<p style={{ textAlign: 'center' }}>- Consent</p>
								</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }}>- Service providers who help us manage personal data</p>
									<p style={{ textAlign: 'center' }}>- External Specialists who are bound by confidentiality obligations under applicable laws and regulations, such as lawyers or tax accountants </p>
									<p style={{ textAlign: 'center' }}>- Officers and employees of our group companies who manage personal data for our business</p>
								</td>
							</tr>
							<tr>
								<td style={{ border: '2px solid #000', textAlign: 'center' }}>3</td>
								<td style={{ border: '2px solid #000', textAlign: 'center' }}>
									<p>IP Address,</p>
									<p>Cookies,</p>
									<p>Web Browser Information (type, version, browser language, browser screen size, scroll information, etc.)</p>
									<p>and Browsing Activities</p>
								</td>
								<td style={{ border: '2px solid #000', textAlign: 'center' }}>Data Subjects him/herself	</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }}>1. To provide or improve our services, and to develop new services</p>
									<p style={{ textAlign: 'center' }}>2. To implement marketing activities</p>
								</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }}>- Legitimate interest</p>
									<p style={{ textAlign: 'center' }}>- Consent</p>
								</td>
								<td style={{ border: '2px solid #000' }}>
									<p style={{ textAlign: 'center' }}>- Service Providers which support our management of personal data</p>
									<p style={{ textAlign: 'center' }}>- Officers and Employees of our group companies managing personal data for our business</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='text-justify px-8 pt-4'>Our "legitimate interests," as stated above, are to manage personal data in order to timely and effectively provide our service to data subjects and to manage personal data in a way that ensures the security of personal data. Please be aware that if your interests or fundamental rights and freedoms require the protection of personal data, we will not collect or process that data. Transfer to Third Countries Your personal data may only be transferred to a controller or processor in a third country for the purposes outlined in this privacy policy if the transfer is permitted by the relevant laws and regulations.</div>
				<div className='text-justify px-8 pt-2'>If you live in the EEA, the following situations are valid:</div>
				<div className='text-justify px-12 pt-2'>(a) The European Union Commission has determined that a particular third country offers an adequate level of protection.</div>
				<div className='text-justify px-12 pt-2'>(b) Standard contractual clauses or standard data protection clauses adopted by the EU Commission have been entered into between us and such a controller or processor.</div>
				<div className='text-justify px-12 pt-2'>(c) This transfer is required in order for us to fulfil our obligations under a contract with you or to carry out precontractual actions you requested.</div>
				<div className='text-justify px-12 pt-2'>(d) You explicitly consented to the proposed transfer after being informed that it may result in the leakage of or pose other risks to your personal data in the absence of an adequacy decision and suitable safeguards.</div>
				<div className='text-justify px-12 pt-2'>(e) Such transfer is required for the conclusion or performance of a contract concluded in your interest between another natural or legal person and us.</div>
				<div className='text-justify px-8 pt-3'>Quotation Request and Inquiry Any personal information that you voluntarily submit to us via the website's quotation request or inquiry form will only be collected and processed by us if you explicitly agree to this. When you press the send button after checking the consent checkbox on these forms, you are deemed to have given your consent to our collection and processing of your personal data. Without your permission, your personal information won't be disclosed to any third parties unless doing so is necessary to carry out your request.</div>
				<div className='text-justify px-8 pt-2'>Don't sell personal information we have never sold or will ever sell your personal information.</div>
				<div className='text-justify px-8 pt-2'>Data Security We take a number of organizational and technical security measures to safeguard your personal information against loss, theft, alteration, or misuse, as well as other threats.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Period of Data Storage</div>
				<div className='text-justify px-8'>The following is the data storage duration:</div>
				<div className='text-justify px-8'>(a) The retention period for personal data in the Privacy Policy collected through the inquiry or request for quotation form on this website shall be 5 years from the date of receipt of the inquiry, with the exception that (b): Until you request the deletion of such personal data; (b) Any data listed in Item "Server log files": After receiving such data, for 90 days; (c) any cookie data: in accordance with our cookie policy.</div>
				<div className='text-justify px-8'>Despite the aforementioned, we adhere to any limitations on the length of data storage periods set forth by applicable laws and regulations.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Data portability, access, and rectification</div>
				<div className='text-justify px-8'>You have the right to obtain your personal data in a structured, generally accepted, and machine-readable format, as well as to access, modify, or erase it. You also have the right to restrict or object to the processing of your personal data. Additionally, you have the right to revoke your consent at any time for us to collect and use your personal information; however, doing so will not invalidate our use of your data in accordance with your consent up until the time of your withdrawal. If you make such a request, kindly let us know.</div>
				<div className='text-justify px-8 pt-2'>By sending an email or a written request letter, please include the following information with your request: (a) your name; (b) contact information; (c) any information that helps us identify the personal data subject to the request; and (d) if the request is made through an authorized agent, the pdf of a document signed by you authorizing the agent to make the request on your behalf. We will begin reviewing your request as soon as we receive it and will reply to you.</div>
				<div className='text-justify px-8 pt-2 font-bold'>Attention:</div>
				<div className='text-justify px-8'>IDEACAR .Co. Ltd.</div>
				<div className='text-justify px-8'>〒231-0023 </div>
				<div className='text-justify px-8'>7f, 26 YamashitachōNaka Ward, </div>
				<div className='text-justify px-8'>Yokohama, Kanagawa, Japan</div>
				<div className='text-justify px-8'>Email: info@ideacar.co</div>
				<div className='text-justify px-8 pt-3 font-bold'>With no exceptions</div>
				<div className='text-justify px-8'>You have a right to be treated equally by the company if you exercise the privacy rights granted to you by the relevant laws and regulations.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Complaint</div>
				<div className='text-justify px-8'>According to the relevant laws and regulations, you have the right to file a complaint about how we handled your personal data with a supervisory authority that is appropriate for your country of residence.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Not Required by Law or Contract</div>
				<div className='text-justify px-8'>The provision of your personal data is neither a statutory requirement nor a contractual requirement, unless we have separately agreed. You won't experience any negative effects from not providing your personal data if it is neither a statutory requirement nor a contractual requirement.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Processing Automation</div>
				<div className='text-justify px-8'>Your personal information may be used to (i) tailor our services to your needs on our websites, or (ii) optimize how you use our websites through an automated decision-making procedure that may involve profiling.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Processor Use</div>
				<div className='text-justify px-8'>For the reasons outlined in this document, we might contract with specific processors to handle your personal data. In this situation, we only work with processors who can implement the necessary organizational and technical safeguards to ensure that processing complies with all relevant legal and regulatory requirements and safeguards your rights.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Personal information about children under 16</div>
				<div className='text-justify px-8'>If you are located in the EEA and are younger than 16 years old, you must first get your guardian's permission before disclosing any personal information. Before checking the consent box on the quotation request or inquiry form on our website, please get your guardian's permission.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Cookie Guidelines</div>
				<div className='text-justify px-8'>In order to enhance functionality, give you a better browsing experience, and customize our social media advertising, we use cookies on our websites. Please see the following cookie policies for more information:</div>
				<div className='text-justify px-8 pt-2'>Cookie Policy for Ideacar: <a href='https://ideacar.co/policy/cookie.html' className='font-bold text-blue-600'>https://ideacar.co/policy/cookie.html</a> Servers' logs, so-called server log files, which your browser automatically sends to us, contain information that our website automatically collects and saves.</div>
				<div className='text-justify px-8 pt-4'>These are:</div>
				<div className='text-justify px-12'>•	IP Address</div>
				<div className='text-justify px-12'>•	Time of server request</div>
				<div className='text-justify px-12'>•	Referrer URL</div>
				<div className='text-justify px-12'>•	Methods to access</div>
				<div className='text-justify px-12'>•	Returned HTTP status code</div>
				<div className='text-justify px-12'>•	Processing time to return</div>
				<div className='text-justify px-8 pt-2'>Only analysis and statistical purposes will be served by the use of these data. No other data sources are combined with these data. If we later learn of specific evidence of illegal use, we reserve the right to review these data.</div>
				<div className='text-justify px-8 pt-3 font-bold'>Internet Analytics</div>
				<div className='text-justify px-8'>Through the cookie setting, you can stop Google Analytics from collecting your data (please refer to each Cookie Policy).</div>
				<div className='text-justify px-8 pt-2 pb-6'>Additionally, by clicking on the following link, an opt-out cookie that prevents the collection of your data on subsequent visits to this site is set, preventing Google Analytics from collecting your data. Google Analytics can be turned off at tools.</div>
			</div>
		</Public>
	)
}

export default PrivacyPolicy
