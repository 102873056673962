import common from './common.json'
import RegardingThihWebsite from './RegardingThisWebsite.json'
import BuyingPayment from './Buying&Payment.json'
import Documents from './Documents.json'
import ShippingReceiving from './Shipping&Receiving.json'
import Button from '../../../components/Buttons/Button'
import Public from '../../../Layout/Public'
import blackmeter from './../../../assets/blackmeter.jpeg'
import GroupQuesBlock from './GroupQuesBlock'


const FAQ = () => {
	// const [id, setId] = useState('')
	const howToBuyD = 'M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
	const aboutUsD = 'M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25'
	const documentD = 'M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'



	return (
		<Public>
			<div className='bg-gray-300 mt-24 h-full'>
				<div
					className={`flex justify-center items-center h-32 sm:h-80 text-secondary text-2xl sm:text-6xl`}
					style={{
						backgroundImage: `url(${blackmeter})`,
						// width: '100%',
						// height: '100%',
						backgroundSize: '100% 100%',
						backgroundRepeat: 'no-repeat'
					}}
				>
					<div className='w-6/12 text-center'>FAQ</div>
					<div className='w-6/12'></div>
				</div>
				<div className='flex flex-col mx-5 sm:mx-12 md:mx-16'>
					<div className='flex justify-between items-center flex-col sm:flex-row mt-6'>
						<div className='max-sm:mt-3'>
							<Button title='How to buy' d={howToBuyD} bgColor='bg-white' fontSize='font-bold' width='w-40' iconStrokeWidth='2' />
						</div>
						<div className='max-sm:mt-3'>
							<Button title='About us' d={aboutUsD} bgColor='bg-white' fontSize='font-bold' width='w-40' iconStrokeWidth='2' />
						</div>
						<div className='max-sm:mt-3'>
							<Button title='Document' d={documentD} bgColor='bg-white' fontSize='font-bold' width='w-40' iconStrokeWidth='2' />
						</div>
					</div>
					<div className='text-secondary text-3xl font-bold text-start my-6'>General Questions</div>
					<GroupQuesBlock title='Common Questions' data={common} />
					<GroupQuesBlock title='Regarding This Website' data={RegardingThihWebsite} />
					<GroupQuesBlock title='Buying & Payment' data={BuyingPayment} />
					<GroupQuesBlock title='Documents' data={Documents} />
					<GroupQuesBlock title='Shipping & Receiving' data={ShippingReceiving} />
				</div>
			</div>
		</Public>
	)
}

export default FAQ
