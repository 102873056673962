import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import BgVideo from './../../assets/videos/car_drift.mp4'
// import BgVideo from './../../assets/videos/drift_white.mp4'
import BgVideo from './../../assets/videos/car_drift_720.mp4'
import SearchBox from './SearchBox'
import VideoPlayer from "react-background-video-player";

const SearchBoxBG = ({
    conColor,
    bgColor,
    bgImage,
    inputColor,
    buttonColor,
    textColor,
    numColor,
    searchTitleBorderColor,
    searchTitleColor,
    searchTitleBoxOpacity,
    searchBoxOpacity
}) => {
    const containerColor = conColor ? conColor : null

    return (
        <div
            className={`h-screen ${containerColor}`}
        // style={{
        //     backgroundImage: `url(${conColor ? null : BgImage})`,
        //     // width: '100%',
        //     // height: '33%',
        //     backgroundSize: 'h-full h-full',
        //     backgroundRepeat: 'no-repeat'
        // }}
        >
            <div className='md:ml-20 pt-10 mb-5 flex flex-col justify-center max-md:items-center h-screen'>
                <div className='flex flex-row sm:flex-col pb-1 mb-10'>
                    <h2 className='text-xl sm:text-3xl max-sm:mr-2 font-medium text-white'>Find Your</h2>
                    <h1 className='text-xl sm:text-5xl max font-bold text-white'>Next Car</h1>
                </div>
                <SearchBox
                    bgColor={bgColor}
                    bgImage={bgImage}
                    inputColor={inputColor}
                    buttonColor={buttonColor}
                    textColor={textColor}
                    numColor={numColor}
                    searchTitleBorderColor={searchTitleBorderColor}
                    searchTitleColor={searchTitleColor}
                    searchTitleBoxOpacity={searchTitleBoxOpacity}
                    searchBoxOpacity={searchBoxOpacity}
                />
            </div>

            {/* <video autoPlay loop muted className='absolute h-screen w-full top-0 left-0 object-fill -z-10'>
                <source src={BgVideo} type='video/mp4' />
            </video> */}
            <VideoPlayer
                className="video -z-10"
                src={BgVideo}
                autoPlay={true}
                muted={true}
            />

        </div>

    )
}

export default SearchBoxBG
