import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { userLoggedOut } from '../features/auth/authSlice'

const Admin = ({ children }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [open, setOpen] = useState(true)
	// Auto close menu when on mobile
	const handleResize = () => {
		if (window.innerWidth < 720) {
			setOpen(false)
		} else {
			setOpen(true)
		}
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
	})

	const Menus = [
		{ title: 'GoHome', src: "M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25", route: '/' },
		{ title: 'Create Manufacturer', src: "M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z", route: '/admin/create-manufacturer' },
		{ title: 'Add Car', src: "M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z", route: '/admin/create-car-info' },
		{ title: 'Cars list', src: "M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z", route: '/admin/cars-list' },
		{ title: 'Insert Car parts', src: "M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z", route: '/admin/parts' },
		{ title: 'Change Password', src: "M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z", route: '/admin/change_password' },
		{ title: 'Reports', src: "M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z", route: '#' },
	]



	const handleLogout = () => {
		localStorage.clear()
		dispatch(userLoggedOut())
		// navigate('/')
	}

	return (
		<div>
			<div className='flex'>
				<div
					className={` ${open ? 'w-72' : 'w-20 '
						} bg-gray-700 p-5 pt-8 relative duration-300 z-10`}
				>
					<img
						src={require('./../assets/control.png')}
						alt='/admin/create-manufacturer'
						className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
           		border-2 rounded-full  ${!open && 'rotate-180'}`}
						onClick={() => setOpen(!open)}
					/>
					<div className='flex gap-x-4 items-center'>
						<img
							src={require('./../assets/dashlogo.png')}
							alt='#'
							className={`cursor-pointer duration-500 ${open && 'rotate-[360deg]'
								}`}
						/>
						<h1
							className={`text-white origin-left font-medium text-xl duration-200 ${!open && 'scale-0'
								}`}
						>
							Idea Car
						</h1>
					</div>
					<ul className='pt-6'>
						{Menus.map((Menu, index) => (
							<Link to={Menu.route} key={index}>
								<li
									className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2 ${index === 0 && 'bg-light-white'
										} `}
								>
									{/* <img src={require(`./../assets/${Menu.src}.png`)} alt='#' /> */}
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
										<path strokeLinecap="round" strokeLinejoin="round" d={Menu.src} />
									</svg>

									<span
										className={`${!open && 'hidden'} origin-left duration-200`}
									>
										{Menu.title}
									</span>
								</li>
							</Link>
						))}
						<hr />
						<Link to='/admin/inquiry-reports'>
							<li
								className={`${!open && 'hidden'} origin-left duration-200 flex rounded-md p-2 ml-10 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4`}
							>
								<img src={require(`./../assets/Search.png`)} alt='#' />
								Inquiry Reports
							</li>
						</Link>
						<Link to='/admin/users-reports' >
							<li
								className={`${!open && 'hidden'} origin-left duration-200 flex rounded-md p-2 ml-10 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4`}
							>
								<img src={require(`./../assets/User.png`)} alt='#' />
								Users Reports
							</li>
						</Link>
					</ul>
				</div>
				<div className='h-screen flex-1 overflow-y-auto'>
					{/*====================== nav start ======================== */}

					<nav className=' relative w-full flex flex-wrap items-center justify-between py-4 bg-gray-800 text-gray-200 focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light'>
						<div className='container-fluid w-full flex flex-wrap items-center justify-between px-6'>
							<div
								className='collapse navbar-collapse flex-grow items-center'
								id='navbarSupportedContent'
							></div>
							{/* Collapsible wrapper */}
							{/* Right elements */}
							<div className='flex items-center relative'>
								{/* Icon */}
								{/* <div className='flex dropdown justify-center mx-2'>
									<a
										className='text-gray-500 hover:text-gray-700 focus:text-gray-700 mr-4 dropdown-toggle hidden-arrow flex items-center'
										href='/'
										id='dropdownMenuButton1'
										role='button'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									>
										<svg
											aria-hidden='true'
											focusable='false'
											data-prefix='fas'
											data-icon='bell'
											className='w-4'
											role='img'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 448 512'
										>
											<path
												fill='currentColor'
												d='M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z'
											></path>
										</svg>
										<span className='text-white bg-red-700 absolute rounded-full text-xs -mt-2.5 ml-2 py-0 px-1.5'>
											1
										</span>
									</a>
									<ul
										className='dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none left-auto right-0'
										aria-labelledby='dropdownMenuButton1'
									>
										<li>
											<a
												className='dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'
												href='/'
											>
												Action
											</a>
										</li>
										<li>
											<a
												className='dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100'
												href='/'
											>
												Another action
											</a>
										</li>
										<li>
											<a
												className='
															dropdown-item
															text-sm
															py-2
															px-4
															font-normal
															block
															w-full
															whitespace-nowrap
															bg-transparent
															text-gray-700
															hover:bg-gray-100
														'
												href='/'
											>
												Something else here
											</a>
										</li>
									</ul>
								</div> */}
								{/* <div className='flex dropdown justify-center mx-2'>
									<a
										className='dropdown-toggle flex items-center hidden-arrow'
										href='/'
										id='dropdownMenuButton2'
										role='button'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									>
										<img
											src='https://mdbootstrap.com/img/new/avatars/2.jpg'
											className='rounded-full'
											style={{ height: 25, width: 25 }}
											alt=''
											loading='lazy'
										/>
									</a>
									<ul
										className='
													dropdown-menu
													min-w-max
													absolute
													hidden
													bg-white
													text-base
													z-50
													float-left
													py-2
													list-none
													text-left
													rounded-lg
													shadow-lg
													mt-1
													m-0
													bg-clip-padding
													border-none
													left-auto
													right-0
												'
										aria-labelledby='dropdownMenuButton2'
									>
										<li>
											<a
												className='
															dropdown-item
															text-sm
															py-2
															px-4
															font-normal
															block
															w-full
															whitespace-nowrap
															bg-transparent
															text-gray-700
															hover:bg-gray-100
														'
												href='/'
											>
												Action
											</a>
										</li>
										<li>
											<a
												className='
															dropdown-item
															text-sm
															py-2
															px-4
															font-normal
															block
															w-full
															whitespace-nowrap
															bg-transparent
															text-gray-700
															hover:bg-gray-100
														'
												href='/'
											>
												Another action
											</a>
										</li>
										<li>
											<a
												className='
															dropdown-item
															text-sm
															py-2
															px-4
															font-normal
															block
															w-full
															whitespace-nowrap
															bg-transparent
															text-gray-700
															hover:bg-gray-100
														'
												href='/'
											>
												Something else here
											</a>
										</li>
									</ul>
								</div> */}
								<div className='flex dropdown justify-center mx-2 hover:text-gray-400 cursor-pointer tooltip tooltip-bottom' onClick={handleLogout} data-tip="Logout" >
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
										<path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
									</svg>
								</div>
							</div>
							{/* Right elements */}
						</div>
					</nav>
					{/*====================== nav end ======================== */}

					{children}
					{/* <footer className='h-9 bg-slate-300 flex justify-center items-center'> This is footer</footer> */}
				</div>
			</div>
			{/* <footer className='h-5 bg-slate-300'> This is footer</footer> */}
		</div>
	)
}

export default Admin
