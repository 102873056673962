import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import { useGetFilteredCarsMutation, useGetAllSpecInfoPubMutation, useGetAllOverViewInfoPubMutation } from '../../../features/carsPage/carsPageApi'
import { useGetNewArrivalCarsMutation } from '../../../features/homePage/homePageApi'
import { setPageCount } from '../../../features/carsPage/carsPageSlice'
import { setCurrentPage } from './../../../features/other/otherSlice'
import Public from './../../../Layout/Public'
import List from './List'
import LeftFilterBox from './LeftFilterBox'
import Pagination from '../../../components/Pagination/Pagination'

const CarList = () => {
  const param = useParams()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [carList, setCarList] = useState([])
  const { manufacturersId, models, minYear, maxYear, minPrice, maxPrice, mileageStart, mileageEnd, displacementStart, displacementEnd, transmissionId, fuelId, driveSystemId, steeringId, isRepaired, colors, seatingCapacityStart, seatingCapacityEnd, conditionIds, equipmentIds, intExtIds, safetyEquipsIds, selfDrivingsIds, standardFeatureIds, pageCount, reload } = useSelector(state => state.cars)


  const [getFilteredCars, { data: FilteredCarsByModels, isLoading: FilteredCarsByModelsLoading, isError: FilteredCarsByModelsError }] =
    useGetFilteredCarsMutation() || {}
  const [getNewArrivalCars, { data: newArrivalCarsData, isLoading: newArrivalCarsIsLoading, isError: newArrivalCarsIsError }] = useGetNewArrivalCarsMutation() || {}
  const [getAllSpecInfoPub, { data: specInfoPubData, isLoading: specInfoPubIsLoading, isError: specInfoPubIsError }] = useGetAllSpecInfoPubMutation() || {}
  const [getAllOverViewInfoPub, { data: allOverViewInfoPub, isLoading: allOverViewInfoPubLoading }
  ] = useGetAllOverViewInfoPubMutation() || {}

  useEffect(() => {
    getAllOverViewInfoPub()
    getAllSpecInfoPub()
    dispatch(setCurrentPage({
      currentPage: 'cars'
    }))
    const formData = new FormData()
    if (param.route === 'new-arrival') {
      formData.append('Page', page)
      getNewArrivalCars({ formData })
    } else if (param.route === 'search' || param.route === 'filter-cars') {
      formData.append('ManufacId', manufacturersId.join())
      formData.append('CarModel', models.join())
      formData.append('CarYearStart', minYear)
      formData.append('CarYearEnd', maxYear)
      formData.append('PriceStart', minPrice)
      formData.append('PriceEnd', maxPrice)
      formData.append('MileageStart', mileageStart)
      formData.append('MileageEnd', mileageEnd)
      formData.append('DisplacementStart', displacementStart)
      formData.append('DisplacementEnd', displacementEnd)
      formData.append('TransmissionId', transmissionId)
      formData.append('FuelId', fuelId)
      formData.append('DriveSystemId', driveSystemId)
      formData.append('SteeringId', steeringId)
      formData.append('IsRepaired', isRepaired)
      formData.append('Colors', colors)
      formData.append('SeatingCapacityStart', seatingCapacityStart)
      formData.append('SeatingCapacityEnd', seatingCapacityEnd)
      formData.append('ConditionIds', conditionIds)
      formData.append('EquipmentIds', equipmentIds)
      formData.append('IntExtIds', intExtIds)
      formData.append('SafetyEquipsIds', safetyEquipsIds)
      formData.append('SelfDrivingsIds', selfDrivingsIds)
      formData.append('StandardFeatureIds', standardFeatureIds)
      formData.append('Page', page)
      getFilteredCars({ formData })
    } else {
      formData.append('CarModel', models.join())
      formData.append('Page', page)
      getFilteredCars({ formData })
    }
  }, [page, reload])



  useEffect(() => {
    if (param.route === 'new-arrival') {
      if (!newArrivalCarsIsLoading && !newArrivalCarsIsError && newArrivalCarsData?.data) {
        setCarList(newArrivalCarsData.data.carList)
        if (page === 0) {
          dispatch(
            setPageCount({
              pageCount: newArrivalCarsData.data.totalPageCount
            })
          )
        }
      }
    } else {
      if (!FilteredCarsByModelsLoading && !FilteredCarsByModelsError && FilteredCarsByModels?.data) {
        setCarList(FilteredCarsByModels.data.carList)
        if (page === 0) {
          dispatch(
            setPageCount({
              pageCount: FilteredCarsByModels.data.totalPageCount
            })
          )
        }
      }

    }
  }, [FilteredCarsByModels, newArrivalCarsData])



  const handlePage = n => {
    setPage(n)
  }
  const backwardClick = () => {
    if (page !== 0) setPage(page - 1)
  }
  const forwardClick = () => {
    if (page !== pageCount - 1) setPage(page + 1)
  }




  return (
    <Public>
      <div className='mt-24'>
        <div id="decoration">
          <div id="pill-1" className="pill"></div>
          <div id="pill-2" className="pill"></div>
          <div id="pill-3" className="pill"></div>
          <div id="pill-4" className="pill"></div>
          <div id="pill-5" className="pill"></div>
          <div id="pill-6" className="pill"></div>
          <div id="pill-7" className="pill"></div>
          <div id="pill-8" className="pill"></div>
          <div id="pill-9" className="pill"></div>
          <div id="pill-10" className="pill"></div>
        </div>
        <div className='flex flex-col sm:flex-row w-full h-full bg-white p-5'>
          <LeftFilterBox allOverViewInfoPub={allOverViewInfoPub?.data} allOverViewInfoPubLoading={allOverViewInfoPubLoading} specInfoPubData={specInfoPubData?.data} specInfoPubIsLoading={specInfoPubIsLoading} specInfoPubIsError={specInfoPubIsError} />
          <div className='flex flex-col w-full'>
            {(!FilteredCarsByModelsLoading && !FilteredCarsByModelsError && FilteredCarsByModels?.data) || (!newArrivalCarsIsLoading && !newArrivalCarsIsError && newArrivalCarsData?.data) ? (
              <>
                <List carList={carList} />
                <Pagination page={page} handlePage={handlePage} backwardClick={backwardClick} forwardClick={forwardClick} />
              </>
            ) : ((!FilteredCarsByModelsLoading && FilteredCarsByModelsError) || (!newArrivalCarsIsLoading && newArrivalCarsIsError)) ?
              <div className='flex justify-center text-2xl font-bold text-primary my-10 w-full'>
                Something went wrong...
              </div>
              :
              <div className='flex justify-center items-center my-10 w-full'>
                <PulseLoader color="var(--secondary-color)" size={25} />
              </div>
            }
          </div>
        </div>
      </div>
    </Public>
  )
}

export default CarList
