import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	clearModels: true
}

const filterSlice = createSlice({
	name: 'filter',
	initialState,
	reducers: {
		unCheckAllModels: (state, action) => {
			state.clearModels = true
		}
	}
})

export const { unCheckAllModels } = filterSlice.actions
export default filterSlice.reducer