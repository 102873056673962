import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCarDetailsByIDMutation, useGetAllSpecInfoPubMutation } from '../../../features/carsPage/carsPageApi'
import { carDetails } from '../../../features/carsPage/carsPageSlice'
import { setCurrentPage } from './../../../features/other/otherSlice'
import Public from './../../../Layout/Public'
// import AdPortion from './AdPortion'
import Image from './Image'
import Info from './Info'
// import OverViewInfo from './OverViewInfo'
import SpecificInfo from './SpecificInfo'
import PulseLoader from 'react-spinners/PulseLoader'

const CarDetails = () => {
  const dispatch = useDispatch()
  const { singleCarId, singleCarData } = useSelector(state => state.cars)

  const [
    getAllSpecInfoPub,
    {
      data: allSpecInfoPubData,
      isLoading: allSpecInfoPubLoading,
      isError: allSpecInfoPubError
    }
  ] = useGetAllSpecInfoPubMutation() || {}

  const [
    getCarDetailsByID,
    {
      data: CarDetailsByID,
      isLoading: CarDetailsByIDLoading,
      isError: CarDetailsByIDError
    }
  ] = useGetCarDetailsByIDMutation() || {}


  useEffect(() => {
    dispatch(setCurrentPage({
      currentPage: 'car-details'
    }))
    const formData = new FormData()
    formData.append('Id', singleCarId.join())
    getAllSpecInfoPub()
    getCarDetailsByID({ formData })
  }, [])

  return (
    <Public>
      <div className='bg-white border-t border-gray-300 p-1 sm:p-4 lg:p-6 w-full h-full mt-24'>
        {(!CarDetailsByIDLoading &&
          !CarDetailsByIDError &&
          CarDetailsByID?.data)
          && (!allSpecInfoPubLoading && !allSpecInfoPubError && allSpecInfoPubData?.data)
          ? (
            <>
              <div className='flex flex-row w-full rounded-xl shadow-xl p-4 border border-gray-100 gap-5'>
                <div className='grid grid-cols-1 lg:w-7/12'>
                  <Image
                    featuredImage={CarDetailsByID?.data?.featuredImage}
                    images={CarDetailsByID?.data?.imageNames}
                    videos={CarDetailsByID?.data?.videoLinks}
                  />
                  <div className='block lg:hidden'>
                    <Info data={CarDetailsByID?.data} />
                  </div>
                  <div>
                    <SpecificInfo singleCarData={CarDetailsByID?.data} specificInfoPub={allSpecInfoPubData?.data} />
                  </div>
                </div>
                <div className='w-5/12 hidden lg:block'>
                  <Info data={CarDetailsByID?.data} />
                </div>
              </div>

              {/* <div className='flex flex-row w-full mt-6'>
                <OverViewInfo data={singleCarData} />
                <AdPortion />
              </div> */}
            </>
          ) : (
            <div className='flex justify-center items-center my-10 w-full'><PulseLoader color="var(--secondary-color)" size={25} /></div>
          )}
      </div>
    </Public>
  )
}

export default CarDetails
