import { apiSlice } from './../api/apiSlice'

export const carInfoApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllOverViewInfo: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetAllOverViewInfo',
				method: 'POST',
				body: data.formData,
			})
		}),
		getAllSpecInfo: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetAllSpecInfo',
				method: 'POST',
				body: data.formData,
			})
		}),
		getManufacIDNames: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetManufacIDNames',
				method: 'POST',
				body: data.formData,
			})
		}),
		getCarById: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetCarById',
				method: 'POST',
				body: data.formData,
			})
		}),
		insertCar: builder.mutation({
			query: (data) => ({
				url: '/admin/api/InsertCar',
				method: 'POST',
				body: data.formData,
			})
		}),
		getCarsList: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetAllCars',
				method: 'POST',
				body: data.formData,
			})
		}),
		updateCar: builder.mutation({
			query: (data) => ({
				url: '/admin/api/UpdateCar',
				method: 'POST',
				body: data.formData,
			})
		}),
		deleteCar: builder.mutation({
			query: (data) => ({
				url: '/admin/api/DeleteCar',
				method: 'POST',
				body: data.formData,
			})
		}),
		getSortedCar: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetSortedCar',
				method: 'POST',
				body: data.formData,
			})
		}),
		insertCarParts: builder.mutation({
			query: (data) => ({
				url: '/admin/api/UpsertCarParts',
				method: 'POST',
				body: data.formData,
			})
		}),
		getExistingCarParts: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetExistingCarParts',
				method: 'POST',
				body: data.formData,
			})
		}),
	})
})

export const { useGetAllOverViewInfoMutation, useGetAllSpecInfoMutation, useGetManufacIDNamesMutation, useGetCarByIdMutation, useInsertCarMutation, useGetCarsListMutation, useUpdateCarMutation, useDeleteCarMutation, useGetSortedCarMutation, useInsertCarPartsMutation, useGetExistingCarPartsMutation } = carInfoApi