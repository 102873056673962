import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Admin from './../../../../Layout/Admin'
import { CSVLink } from 'react-csv'
import { useGetAllInquiriesRptMutation } from './../../../../features/reports/reportsApi'
import { userLoggedOut } from './../../../../features/auth/authSlice'
import { setPageCount } from '../../../../features/carsPage/carsPageSlice'
import Pagination from '../../../../components/Pagination/Pagination'

const InquiryReports = () => {
	const dispatch = useDispatch()
	const [page, setPage] = useState(0)
	const { pageCount } = useSelector(state => state.cars)
	const auth = JSON.parse(localStorage.getItem('auth'))

	const [getAllInquiriesRpt, { data, isLoading, isError, error }] =
		useGetAllInquiriesRptMutation() || {}


	useEffect(() => {
		const formData = new FormData()
		formData.append('userName', `${auth.userName}`)
		formData.append('Page', page)
		getAllInquiriesRpt({ formData })
	}, [page])

	useEffect(() => {
		if (!isLoading && isError && error.status === 401) {
			localStorage.clear()
			dispatch(userLoggedOut())
		}
	}, [isLoading])

	useEffect(() => {
		if (!isLoading && !isError && data?.data) {
			if (page === 0) {
				dispatch(
					setPageCount({
						pageCount: data.data.totalPageCount
					})
				)
			}
		}
	}, [])


	const handlePage = n => {
		setPage(n)
	}
	const backwardClick = () => {
		if (page !== 0) setPage(page - 1)
	}
	const forwardClick = (n) => {
		if (page !== pageCount - 1) setPage(page + 1)
	}



	return (
		<Admin>
			<div className='m-5'>
				{!isLoading && !isError && data?.data ?
					<div>
						<div>
							<CSVLink
								filename={'Users List'}
								data={data?.data?.inquiryList}
								className='btn btn-primary'
							// onClick={() => {
							// 	message.success('The file is downloading')
							// }}
							>
								<button
									// onClick={saveExcel}
									className='bg-gray-700 hover:bg-gray-800 px-4 py-2 text-white font-bold border-gray-900 border-b-2 rounded'
								>
									Export to CSV
								</button>
							</CSVLink>
						</div>

						<div className='overflow-x-auto relative shadow-md sm:rounded-lg mt-7'>
							<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
								<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
									<tr>
										<th scope='col' className='py-3 px-6 text-center'>
											Id
										</th>
										<th scope='col' className='py-3 px-6 text-center'>
											Car Name
										</th>
										<th scope='col' className='py-3 px-6 text-center'>
											Car Model
										</th>
										<th scope='col' className='py-3 px-6 text-center'>
											Car Year
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											Name
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											Company Name
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											Email
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											Country
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											Phone
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											Comments
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											Receive News letter
										</th>
										<th scope='col' className='py-3 px-2 text-center'>
											CreatedDate
										</th>
									</tr>
								</thead>

								<tbody>
									{data.data.inquiryList.map((item, i) =>

										<tr
											key={i}
											className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'
										>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.inquiryId}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.carName}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.carModel}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.carYear}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.name}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.companyName}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.email}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.country}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.phone}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.comments}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.receiveNewsletter}
											</td>
											<td
												className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center'
											>
												{item.createdDate}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<Pagination page={page} handlePage={handlePage} backwardClick={backwardClick} forwardClick={forwardClick} />
					</div>
					:
					<div className='text-xl font-bold text-center'>Loding...</div>
				}
			</div>
		</Admin>
	)
}

export default InquiryReports
