import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { selectedManufacturers } from '../../../features/carsPage/carsPageSlice'

const SingleManufacturer = ({ data, trigger }) => {
  const dispatch = useDispatch()
  const [unCheck, setUnCheck] = useState(true)

  useEffect(() => {
    setUnCheck(true)
  }, [trigger])

  const selectManufacturerHandeler = manufacturer => {

    dispatch(
      selectedManufacturers({
        manufacturersId: manufacturer
      })
    )
    setUnCheck(!unCheck)
  }

  return (
    <div className='my-3 ml-4'>
      <div className='flex flex-row justify-start items-center'>
        <div
          className={`border-2 border-solid border-secondary mr-2 rounded ${unCheck ? 'w-6 h-6' : null
        }`}
          onClick={() => selectManufacturerHandeler(data.manufacId)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={4}
            // stroke='red'
            className={`w-5 h-5 ${unCheck ? 'hidden' : 'block stroke-secondary'}`}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M4.5 12.75l6 6 9-13.5'
            />
          </svg>
        </div>
        <div>{data.manufacName}</div>
      </div>
    </div>
  )
}

export default SingleManufacturer
