import React from 'react'
import SingleSpecInfo from './SingleSpecInfo'

const SpecificInfo = ({ singleCarData, specificInfoPub }) => {

  const conditions = singleCarData.conditionIds.map(i =>
    specificInfoPub.carConditions.find(item => item.id === i)
  )
  const equipments = singleCarData.equipmentIds.map(i =>
    specificInfoPub.equipments.find(item => item.id === i)
  )
  const intExts = singleCarData.intExtIds.map(i =>
		specificInfoPub.intExts.find(item => item.id === i)
  )
  const safetyEquips = singleCarData.safetyEquips.map(i =>
		specificInfoPub.safetyEquips.find(item => item.id === i)
  )
  const selfDrivings = singleCarData.selfDrivings.map(i =>
		specificInfoPub.selfDrivings.find(item => item.id === i)
  )
  const standardFeatures = singleCarData.standardFeatureIds.map(i =>
		specificInfoPub.standardFeatures.find(item => item.id === i)
  )

  return (
    <div className='bg-white w-full p-2 mt-3 rounded'>
      <div className='text-2xl font-bold text-cadmium leading-10'>
        Specification
      </div>
      <div className=' border-b border-gray-300 mt-2 mb-2'></div>
      <SingleSpecInfo title='Car Conditions' data={conditions} />
      <SingleSpecInfo title='Equipments' data={equipments} />
      <SingleSpecInfo title='Interior / Exterior' data={intExts} />
      <SingleSpecInfo title='Safety Equipments' data={safetyEquips} />
      <SingleSpecInfo title='Self Drivings' data={selfDrivings} />
      <SingleSpecInfo title='Standard Features' data={standardFeatures} singleCarData={singleCarData} />
    </div>
  )
}

export default SpecificInfo
