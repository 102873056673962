import { apiSlice } from "../api/apiSlice";

export const manufacturerApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllManufacturer: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetAllManufac',
				method: 'POST',
				body: data.formData
			}),

		}),
		getManufacturerById: builder.mutation({
			query: (data) => ({
				url: '/admin/api/GetManufacById',
				method: 'POST',
				body: data.formData
			}),
		}),
		insertManufacturer: builder.mutation({
			query: (data) => ({
				url: '/admin/api/InsertManufac',
				method: 'POST',
				body: data.formData
			}),
			// invalidatesTags: ['Manufacturer']
		}),
		updateManufacturer: builder.mutation({
			query: (data) => ({
				url: '/admin/api/UpdateManufac',
				method: 'POST',
				body: data.formData
			}),
			// invalidatesTags: ['Manufacturer']
		}),
		deleteManufacturer: builder.mutation({
			query: (data) => ({
				url: '/admin/api/DeleteManufac',
				method: 'POST',
				body: data.formData
			}),
			// invalidatesTags: ['Manufacturer']
		})
	})
})

export const { useGetAllManufacturerMutation,useGetManufacturerByIdMutation, useInsertManufacturerMutation, useUpdateManufacturerMutation, useDeleteManufacturerMutation } = manufacturerApi