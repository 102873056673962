import React from 'react'
import { useSelector } from 'react-redux'
import SingleManufacturer from './SingleManufacturer'

const OriginBlocks = ({manufacsOriginWise, clickedUnCheck}) => {
  // const { manufacsOriginWise } = useSelector(state => state.cars)

  return (
    <>
      {manufacsOriginWise.map((item, index) => (
        <div key={index} className='flex flex-col'>
          <div className='flex flex-row justify-start border-b-2 pb-2'>
            {/* <span className='p-4 rounded-full mr-1 bg-gradient-to-r from-cyan-500 to-blue-500'></span> */}
            <div>
              <span className='ml-2 text-4xl font-bold text-black'>
                {item.origin.substring(0, 1)}
              </span>
              <span className='text-lg font-bold text-black'>
                {item.origin.substring(1, item.origin.length)}
              </span>
            </div>

          </div>
          <div className='grid grid-cols-1 sm:grid-cols-4 items-center'>
            {item.manufacs.map((i, index) => (
              <SingleManufacturer key={index} data={i} trigger={clickedUnCheck} />
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default OriginBlocks
